/* Foundation v2.1.4 http://foundation.zurb.com */
/* Artfully masterminded by ZURB  */

/* -------------------------------------------------- 
   Table of Contents
-----------------------------------------------------
:: Shared Styles
:: Page Name 1
:: Page Name 2
*/


/* -----------------------------------------
   Shared Styles
----------------------------------------- */

table.responsive {width:100%;}

table th { font-weight: bold; }
table td, table th { padding: 9px 10px; text-align: left; }

table.responsive tr:nth-child(2n+1) {background-color:rgba(0,0,0,0.05);}

/* Mobile */
@media only screen and (max-width: 767px) {
	
	table.responsive { margin-bottom: 0; }
	
	.pinned { position: absolute; left: 0; top: 0; background-color:rgba(0,0,0,0.1);width:15%; overflow: hidden; overflow-x: scroll; }
	.pinned table { border-right: none; border-left: none; width: 100%; }
	.pinned table th, .pinned table td { white-space: nowrap; }
	.pinned td:last-child { border-bottom: 0; }
	
	div.table-wrapper { position: relative; margin-bottom: 20px; overflow: hidden;}
	div.table-wrapper div.scrollable table { margin-left: 15%;width:85%;}
	div.table-wrapper div.scrollable { overflow: scroll; overflow-y: hidden; }	
	
	table.responsive td, table.responsive th { position: relative; white-space: nowrap; overflow: hidden; }
	table.responsive th:first-child, table.responsive td:first-child, table.responsive td:first-child, table.responsive.pinned td { display: none; }
	
}

/* -----------------------------------------
   Page Name 1
----------------------------------------- */




/* -----------------------------------------
   Page Name 2
----------------------------------------- */

