/*////////////////////////////////////////////////////////////////////////////
Projet : Infinit'Game V1   /////////////////////////////////////////////////
Date : Mars 2013             /////////////////////////////////////////////////
FrontEnd HTML5/CSS3 - Integration & JQUERY : www.gotoandbuzz.com  //////////
////////////////////////////////////////////////////////////////////////////*/
@font-face{
	font-family: 'sfdisplay';
    src: url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/fonts/SfDisplay.otf');
    src: url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/fonts/SfDisplay.otf') format('otf');
    font-weight: normal;
    font-style: normal;
}
@font-face{
	font-family: 'sfdisplaybold';
    src: url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/fonts/SfDisplayBold.otf');
    src: url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/fonts/SfDisplayBold.otf') format('otf');
    font-weight: 700;
}

/*////////////////////////////////////////////////////////////////////////////
RESET ////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////*/
html {margin:0;padding:0;font-size:100%;height:100%;}

img {border:none;}
a { text-decoration:none; cursor:pointer; outline:0 none; color:#fff; }
a:hover { text-decoration:none;cursor:pointer; }
a:focus {outline:0 none;}
object {outline:0;}
ol {padding:0;margin:0;}
li {list-style:none;padding:0;margin:0;}
fieldset { border: 0; }
textarea, input, select { font-size:100%; font-family: inherit; }
.left { text-align:left; }
.right { text-align:right; }
.center { text-align:center; }
.justify { text-align:justify; }
.fL{ float:left;}
.fR { float:right;}
progress {
  background-color: #f3f3f3;
  border: 0;
  height: 18px;
  border-radius: 9px;
   width: 80%;

}
progress::-webkit-progress-bar {
  background-color: #f3f3f3;
  border: 0;
  height: 18px;
     
  border-radius: 9px;
  width: 80%;
 }
progress::-webkit-progress-value {
  background-color: #f3f3f3;
  border: 0;
  height: 18px;
  border-radius: 9px;
   width: 80%;
  }
progress::-moz-progress-bar {
  background-color: #f3f3f3;
  border: 0;
  height: 18px;
  border-radius: 9px;
   width: 80%;
  }


p {margin:0; padding:0;}
.alt-flash { text-align:center; padding:10px; border:1px solid #FFF; width:330px; margin:0 auto; background:#000; }
.alt-flash a { text-decoration:underline; }
.cf { overflow:hidden; }
label { cursor:pointer; }
.relative { position:relative; }
.underline { text-decoration:underline; }
.clearfix:after { content: "."; display: block; height: 0; clear: both; visibility: hidden; }
* html .clearfix { display:inline-block; }
*:first-child+html .clearfix { display:inline-block; }
/* elements en clearfix */
.cf:after { content:"."; display:block; height:0; clear:both; visibility:hidden; }
.cf { display:inline-block; }
/*hides from IE mac \*/
* html .cf { height:1%; }
.cf { display:block; }
/*end hides from IE mac*/
.inline {display:inline;}
ul { margin:0; padding:0;}
.reset {clear:both;width:100%;height:1px;font-size:1px;}
.clear {clear:both;}
h1,h2,h3,h4,h5,h6,p {margin:0;padding:0;font-weight:normal;}
.hide {display:none;}

.play_button_xxx{
    z-index: 101;
    width: 42%;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
	height:100%
}
#img1 {
	width: 120%;
}
.header_popin_ca{
	width:50%;
	background: lightgray;
    border-radius: 36px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
}	
.relative {
    position:relative;
    top:0;
    left:0;
}
.zindex{
	z-index: 9999;
}
.lowzindex{
	z-index: 1;
}
.marginCenter{
	margin-left:auto;
	margin-right:auto;
}
/*////////////////////////////////////////////////////////////////////////////
Déclarations des classes propriétaires ///////////////////////////////////////
////////////////////////////////////////////////////////////////////////////*/
#homepage-images-2 img {
	max-width:89%;
	max-height:400px;
}
.hidden {
	visibility: hidden;
}
.details_desc{
	text-align:left;
	font-family:robotolight;
	font-size:1.0em;
	color:#9c9c9c;
}
.pt_button_accept{
	width:100%;
	padding-top:4px;
	padding-bottom:4px;
	padding-left:25px;
	padding-right:25px;
	background: #33c73c;
	color:white;
	font-weight:bold;
	-webkit-border-radius:3px; 
	-moz-border-radius:3px; 
	border-radius:3px; 	
}
.pt_button_cancel{
	width:100%;
	padding-top:4px;
	padding-bottom:4px;
	padding-left:25px;
	padding-right:25px;
	background: #e64747;
	color:white;
	font-weight:bold;
	-webkit-border-radius:3px; 
	-moz-border-radius:3px; 
	border-radius:3px; 		
}
.stream4_image1{
position: relative;
  top: 0;
  left: 0;
}

#wrapSITE .account {
top:80px;
}

.stream4_image2{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.stream4_image22{
  top: 0;
  left: 0;
  right: 0;
}
.stream4_parent{
  position: relative;
  top: 0;
  left: 0;
}
.container_lp_ci_10{
  position: relative;
  top: 0;
  left: 0;
}
.noSelect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-tap-highlight-color: transparent;
	
}
.divPTcancel{
	width:70%;
	display:initial;
	margin-top:30px;
	font-size:18px;
}
.bottomCH{
	text-align:justify;
	color:grey;
	width:90%;
    margin-left: auto;
    margin-right: auto;	
}
.scrollmenu_logo{
	padding-left:30px;
	padding-right:30px;
	padding-top:30px;
	text-align:center;
	
}
.scrollmenu_subtitle{
	font-family:robotolight;
	*font-weight:bolder;
	color:#606566;
	padding-bottom:30px;
}
#homepage-images .slides li {
    position: relative;
}
.table1{
	margin: 25px auto 0px;
    width: 95%;
	max-width: 100%;
}
.tabla{
    display: table;
    width: 95%;
    margin: auto;
    padding-top: 100px;
}
.tabla img{
	max-width: 240px !important;
    margin-top: 30px;
}
.table1 .row1:nth-of-type(2n+1) {
    background-color: #EAEAEA;
}
.img-responsive, .thumbnail>img, .thumbnail a>img, .carousel-inner>.item>img, .carousel-inner>.item>a>img{
    display: block;
    max-width: 100%;
    height: auto;
}
.table1 .row1 .cell1{
    float: left;
    text-align: center;
    width: 50%;
    padding: 5px 0px;
    color: #333;
    font-size: 12px;
    font-weight: bold;
}
.row1{
    margin-right: -15px;
    margin-left: -15px;
    margin-bottom: 5px;
}

.table1 .row1{
	overflow: hidden;
    line-height: 32px;
}

.footerlinkslpatx{
	color:white;
	background-color:black;
}

.header_video{
    display: block;
    max-width: 100%;
    height: auto;
	margin-left:auto;
	margin-right:auto;
	width:90%;
}

.buttonXNO{
	display:block;
	padding:10px;
	background: red;
	border-radius:5px;
	text-decoration:none;
	color:#FFFFFF;
	width:90%;
	max-width:500px;
	margin:auto;
	font-size:20px;
}
.popachilo{
	text-align: center;
    padding: 0;
    width: 80%;
    margin: 1% auto;
    font-size: 15px;
    line-height: 15px;
}
.ContainerDiv {
    max-width: 640px;
    width: 90%;
    margin: auto;
    background: rgba(255,255,255,0.6);
    padding: 2%;
    margin-top: -34px;	
}
@keyframes blinker {  
  50% { opacity: 0; }
}
.blink_me {
  animation: blinker 1s linear infinite;
}
.ContainerDiv .questio {
    padding-top: 0px !important;
    line-height: 30px !important;
    font-size: 20px;
}

h1 {
    text-align: center;
    color: #000;
    width: 80%;
    margin: 1% auto;
    font-size: 20px;
}

.ContainerDiv .questio .cta {
    width: 80% !important;
    margin-left: 10% !important;
}

.questio .cta {
    display: block;
    width: 30%;
    line-height: 40px;
    text-decoration: none;
    font-size: 20px;
    color: #FFF;
    background: #467C33;
    text-align: center;
    margin: 5px;
    border: 5px solid #FFF;
    transition-duration: 0.2s;
    transition-property: background-color, transform;
}

.backIMG{
    background-image: url(https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/CA_Background.jpg);
	background-position: center top;
    background-repeat: no-repeat;
}
.layer_billing_stranger_offer{
    font-size: 20px;
    font-weight: bold;
	color: black;
}
.evina_fix{
	display:inline-block;
}
.hide {
    display: none;
}
.questionCA{
	background:transparent;
    text-align: center; 
    color: #000;
    width: 80%;
    margin: 1% auto;
    font-size: 20px;
	text-shadow: none;
    font-weight: bolder;	
}
.cta, .cta_wait { 
    background-color: #13ABFF !important;
    box-shadow: none !important;
    font-weight: 400 !important;
}
#icones {
    padding: 0 0 2% 0 !important;
}
#icones {
    max-width: 640px;
    width: 90%;
    padding: 2%;
    margin: auto;
    font-size: 13px;
    line-height: 14px;
    text-align: center;
    margin-bottom: 3%;
    background: rgba(0,0,0,0.3);
}
#icones .icone img {
    width: 70%;
    display: block;
    margin: auto;
	vertical-align: text-top;
}
.test{
	color:black;
	text-align:center;
}
.inputradio2{
	width:7%;
	padding:0;
}
.inputradio{
	width:10%;
}
.b1{
	width:30%;
	padding:0;

}
.pad0{
	padding:0;
}
.b2{
	width:30%;
	padding:0;

}
.b3{
	width:30%;
}
#icones .icone {
    width: 25%;
    float: left;
    color: #FFF;
    font-size: 11px;
}
.clearblue{
    display: block;
    clear: both;
}
.95w{
	width:95%;
	margin-left:auto;
	margin-right:auto;
}
.spe2{
	font-size:15px;
	color:white;
	padding-left:15px;
	padding-right:15px;
	height: 100%;
	width: 70%;
	margin: 0;
	background: #c50001;
	color: white;
	font-weight: bold;
	letter-spacing: 2px;
	line-height: normal;
	display: inline;
	border-radius: 7px;
	padding-top: 8px;
	padding-bottom: 7px;
	margin-left: auto;
	margin-right: auto;	
}
a.formSubmitRedButton{
	padding-left:25px;
	padding-right:25px;
}
.formSubmitRedButton{
	height: 100%;
	width: 70%;
	padding: 0;
	margin: 0;
	background: #c50001;
	color: white;
	font-weight: bold;
	font-size: 33px;
	letter-spacing: 2px;
	line-height: normal;
	display: inline;
	border-radius: 7px;
	padding-top: 8px;
	padding-bottom: 7px;
	margin-left: auto;
	margin-right: auto;
}
.inputstyleRedButton{
    width: 70%;
    height: 36px;
    line-height: 36px;
   
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#443f3f',endColorstr='#0a0809',GradientType=0);
    transition: left 2s,top 2s,transform 2s;
    -moz-transition: left 2s,top 2s,-moz-transform 2s;
    -webkit-transition: left 2s,top 2s,-webkit-transform 2s;
    -o-transition: left 2s,top 2s,-o-transform 2s;
    color: #efefef;
    text-shadow: 0 1px 2px rgba(0,0,0,.3);
    vertical-align: middle;
}
a.formSubmitBlackButton{
	padding-left:25px;
	padding-right:25px;
}
.formSubmitBlackButton{
	height: 100%;
	width: 70%;
	padding: 0;
	margin: 0;
	background: #2e2c29;
	color: white;
	font-weight: bold;
	font-size: 33px;
	letter-spacing: 2px;
	line-height: normal;
	display: inline;
	border-radius: 7px;
	padding-top: 8px;
	padding-bottom: 7px;
	margin-left: auto;
	margin-right: auto;
}
.aBill {
    margin: 1%;
    padding: 1%;
    margin: auto !important;
    color: #1B72B5;
    border: 1px solid #1B72B5;
    background: #FFF;
}
.greyback{
	background:lightgray;
}
.spacer {
    width: 80%;height:1px;
    margin:8px auto 16px auto;
    background:#2f7a47;
}
#DivCheckboxBE{
    width: 75%;
	margin-left:auto;
	margin-right:auto;
}
.separateur {
    width: 70%;height:6px;
    margin:8px auto 16px auto;
}

.bloc {
    padding:8px;
    -webkit-border-radius:10px; 
    -moz-border-radius:10px; 
    border-radius:10px; 
}
.border_ir{
	border: black 1px solid;
	background:black ;
}

.border {border:#2f7a47 1px solid;}
.border_bottom{border-bottom:#4A334A 1px solid;}
.border_top{border-top:#c0c0c0 1px solid;}

.btnGEN {
    height: 32px;
    -webkit-border-radius:5px; 
    -moz-border-radius:5px; 
    border-radius:5px;   
    background: rgb(68,63,63);
    background: -moz-linear-gradient(top,  rgba(68,63,63,1) 0%, rgba(68,63,63,1) 50%, rgba(10,14,10,1) 51%, rgba(10,8,9,1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(68,63,63,1)), color-stop(50%,rgba(68,63,63,1)), color-stop(51%,rgba(10,14,10,1)), color-stop(100%,rgba(10,8,9,1)));
    background: -webkit-linear-gradient(top,  rgba(68,63,63,1) 0%,rgba(68,63,63,1) 50%,rgba(10,14,10,1) 51%,rgba(10,8,9,1) 100%);
    background: -o-linear-gradient(top,  rgba(68,63,63,1) 0%,rgba(68,63,63,1) 50%,rgba(10,14,10,1) 51%,rgba(10,8,9,1) 100%);
    background: -ms-linear-gradient(top,  rgba(68,63,63,1) 0%,rgba(68,63,63,1) 50%,rgba(10,14,10,1) 51%,rgba(10,8,9,1) 100%);
    background: linear-gradient(to bottom,  rgba(68,63,63,1) 0%,rgba(68,63,63,1) 50%,rgba(10,14,10,1) 51%,rgba(10,8,9,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#443f3f', endColorstr='#0a0809',GradientType=0 );     
    transition: left 2s, top 2s, transform 2s;
    -moz-transition: left 2s, top 2s, -moz-transform 2s;
    -webkit-transition: left 2s, top 2s, -webkit-transform 2s;
    -o-transition: left 2s, top 2s,-o-transform 2s;               
    padding:0 18px;
    font-size:1.4em;color: #efefef;text-shadow: 0 1px 2px rgba(0,0,0,.3);line-height:32px;
    vertical-align: middle;
    display:inline-block;
}

.BTN {
    -webkit-border-radius:5px; 
    -moz-border-radius:5px; 
    border-radius:5px;   
    background: rgb(68,63,63);
    background: -moz-linear-gradient(top,  rgba(68,63,63,1) 0%, rgba(68,63,63,1) 50%, rgba(10,14,10,1) 51%, rgba(10,8,9,1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(68,63,63,1)), color-stop(50%,rgba(68,63,63,1)), color-stop(51%,rgba(10,14,10,1)), color-stop(100%,rgba(10,8,9,1)));
    background: -webkit-linear-gradient(top,  rgba(68,63,63,1) 0%,rgba(68,63,63,1) 50%,rgba(10,14,10,1) 51%,rgba(10,8,9,1) 100%);
    background: -o-linear-gradient(top,  rgba(68,63,63,1) 0%,rgba(68,63,63,1) 50%,rgba(10,14,10,1) 51%,rgba(10,8,9,1) 100%);
    background: -ms-linear-gradient(top,  rgba(68,63,63,1) 0%,rgba(68,63,63,1) 50%,rgba(10,14,10,1) 51%,rgba(10,8,9,1) 100%);
    background: linear-gradient(to bottom,  rgba(68,63,63,1) 0%,rgba(68,63,63,1) 50%,rgba(10,14,10,1) 51%,rgba(10,8,9,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#443f3f', endColorstr='#0a0809',GradientType=0 );     
    transition: left 2s, top 2s, transform 2s;
    -moz-transition: left 2s, top 2s, -moz-transform 2s;
    -webkit-transition: left 2s, top 2s, -webkit-transform 2s;
    -o-transition: left 2s, top 2s,-o-transform 2s;               
    padding:0 18px;
    color: #efefef;text-shadow: 0 1px 2px rgba(0,0,0,.3);
    vertical-align: middle;
    display:inline-block;
}

.spacerMID80 {
    position:relative;top:0;
    width:80%;height:14px;
	margin: 20px auto 18px auto;
} 
.spacerMID80 .separateur {
    position:absolute;top:0;
    width:30%;height:4px;
    margin:0;
}           
.spacerMID80 .separateurL {
    left:0;
}
.spacerMID80 .separateurR {
    right:0;
}         
.spacerMID80 p {
    position:absolute;top:-6px;left:0;
    width:100%;
    font-size:1.2em;
}   
.disabled{
	opacity: 0.4;
	 pointer-events: none !important;
}
.enabled{
	opacity: 1;
}
.th_wrapper {
  position: relative;
  display: inline-block;
}
.th_close:before {
  content: '✕';
}
.th_close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: black;
  font-size: 46px;
  margin-right: 20px;
  margin-top: 10px;
}

.boxy {
	
    position:relative;top:0;left:0;
    margin: 0 auto;
    padding:0 0 20px 0;
    -webkit-border-radius:10px; 
    -moz-border-radius:10px; 
    border-radius:10px; 
    margin:0 auto 55px auto;
    max-width:800px;
    background: rgb(27,48,31);
    background: -moz-linear-gradient(top,  rgba(27,48,31,1) 0%, rgba(27,35,24,1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(27,48,31,1)), color-stop(100%,rgba(27,35,24,1)));
    background: -webkit-linear-gradient(top,  rgba(27,48,31,1) 0%,rgba(27,35,24,1) 100%);
    background: -o-linear-gradient(top,  rgba(27,48,31,1) 0%,rgba(27,35,24,1) 100%);
    background: -ms-linear-gradient(top,  rgba(27,48,31,1) 0%,rgba(27,35,24,1) 100%);
    background: linear-gradient(to bottom,  rgba(27,48,31,1) 0%,rgba(27,35,24,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1b301f', endColorstr='#1b2318',GradientType=0 );

}
.bottomLPAffiFree{
	color:#a2a2a2;
	text-align:center;
	font-size:1em
}
.bottomLPAffiFreeCH{
	color:#a2a2a2;
	text-align:center;
	font-size:1em
}
.imgOkButtonBE3G{
	margin-left:auto;
	margin-right:auto;
}
.blocklogoloupe{
	background-color:grey;
	padding-top:10px;
	padding-bottom:10px;
}
.logoinsideblock{
	width:30%;
}
.logoinsideblock2{
	width:60%;
}
.textcentral1{
	font-size:19px;
	text-align:center;
	letter-spacing: 3px;
	font-weight:bold;
}
.textcentral2{
	text-align:center;
	letter-spacing: .5px;
	font-weight:bold;
	font-size: 16px;
}
.textcentral3{
	text-align:center;
	font-size: 12px;	
}
.textcentral4{
	text-align:center;
	font-size: 11px;	
}
.blockcentralwhite{
	background-color:white;
	color:black;
}
.block_cpt{
	font-size:13px;
	color:black;
}
.progress-value{
	color: blue;
	font-size:15px;
}
.blockleftwhite{
	background-color:white;
	color:black;
	text-align:left;
}
.blockline1{
	text-align:left;
	color: blue;
	text-decoration:underline;
}
.fullheight{
	height:100%;
}
.divbottomLPAffiFree_2{
    color: #a2a2a2;
    text-align: center;
    background-color: lightgray;
    font-size: 1.3em;
	margin-top:5px;
    padding-top: 6px;
    padding-bottom: 6px;	
}
.divbottomLPAffiFree_2t{
    color: #404746;
    text-align: center;
    background-color: #d3d3d38a;
    font-size: 1.3em;
	margin-top:5px;
    padding-top: 6px;
    padding-bottom: 6px;	
}
.globalbottomLPaffi{

    bottom: 20px;
    position: fixed;
    width: 100%;
}
.divlaunchvideo{
	background:black;
	width:40%;
    margin-left: auto;
    margin-right: auto;	
	padding-bottom:10px;
}
.picLol{
	width:40%;
}
.inside_menu_lp{
    width: 25%;
    background: #525868;
    padding: 1px;
	padding-top:5px;
	padding-bottom:5px;
    border: solid 1px white;
}
.popinStream{
    -webkit-border-radius:5px; 
    -moz-border-radius:5px; 
    border-radius:5px;   
	
}
.head_menu_lp{
	width:100%;
	display: flex;
    font-size: 17px;
    color: white;
}
.popin_stream_button{
	padding:5px;
	background:#ff7900;
	width:60%;
	margin-left:auto;
	margin-right:auto;
	font-size:22px;
	color:black;
	border-radius:5px;
}


.txtvideoname{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
	font-weight:bold;
	text-align: left;
	font-size: small;
	/* width: 92%; */
	padding-left: 4%;
	max-width: 92%;
	margin-bottom:8px;
}
.timeCaption{
    font-family: robotoregular;
    position: absolute;
    bottom: 0;
    margin-bottom: 25px;
    margin-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 12px;
    padding-right: 12px;
    color: black;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background-color: #e8b5ad75;
    font-size: 1.3em;
    letter-spacing: 1px;
}

.boxy p {

}
.boxy p span.small {
    font-size:1.0em;
}
.boxy p span.big {
    font-size:1.6em;
}
.boxy .wrapBTN {
    position:absolute;
    bottom:-16px;
    left:0;
    width:100%;
    text-align:center;
}
.boxy .wrapBouyguesBTN {
    text-align:center;
}
.boxy .wrapBouyguesUnsubBTN {
    text-align:left;
}
.boxy .wrapBTN a {
    margin:0 auto;
}
.boxy .wrapTITTLE {
    width:100%;
    text-align:center;
    -webkit-border-radius:10px 10px 0 0; 
    -moz-border-radius:10px 10px 0 0;
    border-radius:10px 10px 0 0; 
    background: rgb(100,182,72);
    background: -moz-linear-gradient(top,  rgba(100,182,72,1) 0%, rgba(100,182,72,1) 50%, rgba(69,173,48,1) 50%, rgba(69,173,48,1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(100,182,72,1)), color-stop(50%,rgba(100,182,72,1)), color-stop(50%,rgba(69,173,48,1)), color-stop(100%,rgba(69,173,48,1)));
    background: -webkit-linear-gradient(top,  rgba(100,182,72,1) 0%,rgba(100,182,72,1) 50%,rgba(69,173,48,1) 50%,rgba(69,173,48,1) 100%);
    background: -o-linear-gradient(top,  rgba(100,182,72,1) 0%,rgba(100,182,72,1) 50%,rgba(69,173,48,1) 50%,rgba(69,173,48,1) 100%);
    background: -ms-linear-gradient(top,  rgba(100,182,72,1) 0%,rgba(100,182,72,1) 50%,rgba(69,173,48,1) 50%,rgba(69,173,48,1) 100%);
    background: linear-gradient(to bottom,  rgba(100,182,72,1) 0%,rgba(100,182,72,1) 50%,rgba(69,173,48,1) 50%,rgba(69,173,48,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#64b648', endColorstr='#45ad30',GradientType=0 );

    margin:0 0 20px 0;
    
}
.boxy .wrapTITTLE p {
    width:100%;
    height:36px;line-height:36px;   
    margin: 0 auto;
    padding:0;
    font-size:1.8em;  
}    

.tab-content {
    overflow: visible;
}
.btnCGUCGV {
    position:absolute;bottom:-35px;right:0;
    width:100%;
    text-align:left;
}
.btnCGUCGV a {
    color: #64b648;
}

.width10 {width:10%;}
.width20 {width:20%;}
.width30 {width:30%;}
.width40 {width:40%;}
.width50 {width:50%;}
.width60 {width:60%;}
.width70 {width:70%;}
.width80 {width:80%;}
.width90 {width:90%;}
.width100 {width:100%;}

.btn16 {height:16px;font-size:1.0em;line-height:16px;}
.btn26 {height:26px;font-size:1.1em;line-height:26px;}
.btn36 {height:36px;font-size:1.5em;line-height:36px;}
.btn46 {height:46px;font-size:1.5em;line-height:46px;}

.btn36x2 {height:70px;line-height:35px;}

.star{
	height:15px;
	width:15px;
}

.bouyguesDetails{
	text-align:center;
	padding-left:20px;
	padding-right:20px;
}
.invisible{
	visibility:hidden;
}

/*///////////////////////////////////////////////////////////////////////////////////////////////////////////
STRUCTURE - MAIN ( Body > Main > mainHEADER _ mainMEA _ mainFORM _ mainCLIENTS _ mainSKILLS _ mainFOOTER ////
///////////////////////////////////////////////////////////////////////////////////////////////////////////*/
body {height:100%;font-family:'robotoregular',arial,sans-serif;font-size:12px;color:#ffffff;background:#000000;padding:0;margin:0;text-align:center;line-height:1;}

    .mid {
		margin-bottom: 55px;
        top:0;left:0;
        width:100%;
        margin:0;
    }
    .popipo {
		margin-bottom: 55px;
        top:0;left:0;
        width:100%;
        margin:0;
    }	
    .content {margin:0 auto;}
    .inner {
        position:relative;top:0;left:0;
        width:100%;
        margin:0 auto;
    }
    
    h1 {
        padding:10px 0;
        font-size:2em;text-shadow: 0px 0px 4px rgba(0,0,0,0.6);
        filter: dropshadow(color=#000000, offx=0, offy=0);
    }
    
/* LAYER LANDSCAPE */    
    .layerLANDSCAPE {
        position:fixed;top:0;left:0;z-index:8999;
        width:100%;height:100%;
        display:none;
        opacity:0;
        -webkit-transition: all 0.8s ease-in-out;
        -moz-transition: all 0.8s ease-in-out;
        -o-transition: all 0.8s ease-in-out;
        transition: all 0.8s ease-in-out;           
    }

    .layerLANDSCAPE2 {
        position:fixed;top:0;left:0;z-index:8999;
        width:100%;height:100%;
        display:none;
        opacity:0;
        -webkit-transition: all 0.8s ease-in-out;
        -moz-transition: all 0.8s ease-in-out;
        -o-transition: all 0.8s ease-in-out;
        transition: all 0.8s ease-in-out;           
    }	
			.layerLANDSCAPE_BT2 {
                position:fixed;top:0;left:0;z-index:9999;
                width:100%;height:100%;
                background:#391a39 url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/BT_nolandscape.png') no-repeat 50% 50%;
                background-size:contain;
                opacity:0;
                display:none;
            }		
	
    .layerLANDSCAPE_BT {

        position:fixed;top:0;left:0;z-index:8999;
        width:100%;height:100%;
        display:none;
        opacity:0;
        -webkit-transition: all 0.8s ease-in-out;
        -moz-transition: all 0.8s ease-in-out;
        -o-transition: all 0.8s ease-in-out;
        transition: all 0.8s ease-in-out;           
    }	
    .layerLANDSCAPE_Firefox {
        position:fixed;top:0;left:0;z-index:8999;
        width:100%;height:100%;
        display:none;
        opacity:0;
        -webkit-transition: all 0.8s ease-in-out;
        -moz-transition: all 0.8s ease-in-out;
        -o-transition: all 0.8s ease-in-out;
        transition: all 0.8s ease-in-out;           
    }
/* POPUP */  
#layerOVER{
position:absolute;
top: 0;
left: 0;
z-index: 9900;
width:100%;
height: 100%;
display:none;
}

#layerOVERmarged{
position:absolute;
top: 0;
left: 0;
z-index: 9900;
width:100%;
height: 100%;
display:none;
margin-top:30px;
}

#getCodePopup {
position:absolute;
top: 0;
left: 0;
z-index: 9900;
width:100%;
height: 100%;
}

#layerOVERExplanation {
position:absolute;
top: 0;
left: 0;
z-index: 9900;
width:100%;
height: 100%;
display:none;
}

#datalayerOVERExplanation {
position:absolute;
top: 0;
left: 0;
z-index: 9900;
width:100%;
height: 100%;
display:none;
}

#datalayerOVERExplanation123 {
position:absolute;
top: 20%;
left: 0;
z-index: 1000000;
width:100%;
height: 100%;
}

#layerUnsubEchovox {
position:absolute;
top: 0;
left: 0;
z-index: 9900;
width:100%;
height: 100%;
display:none;
}
#layerEnterYourPhone {
	position:absolute;
	top: 0;
	left: 0;
	z-index: 9900;
	width:100%;
	height: 100%;
	display:none;
}
#layerUnsubFree {
	position:absolute;
	top: 0;
	left: 0;
	z-index: 9900;
	width:100%;
	height: 100%;
	display:none;
}
.vignetteX{
	width:92%;
}
.vignetteStream{
	width:100%;
}
#layerEnterYourPhoneV2 {
	position:absolute;
	top: 0;
	left: 0;
	z-index: 9900;
	width:100%;
	height: 100%;
	display:none;
}
.babos{
line-height:1em;
}
#layerEnterPhoneXXX {
	position:absolute;
	top: 0;
	left: 0;
	z-index: 9900;
	width:100%;
	height: 100%;
	display:none;
}
#layerEnterPhoneCA {
	top: 0;
	left: 0;
	z-index: 9900;
	width:100%;
	height: 100%;
	display:none;
	position:fixed;
}
#layerOperatorPicker {
	top: 0;
	left: 0;
	z-index: 9900;
	width:100%;
	height: 100%;
	display:none;
	position:fixed;
}
#layerEnterPhoneMY {
	top: 0;
	left: 0;
	z-index: 9900;
	width:100%;
	height: 100%;
	display:none;
	position:fixed;
}
.testozz{
	width:100%;
	max-width:500px;
	margin-top:100px;
}
#layerConfirmAT {
	position:absolute;
	top: 0;
	left: 0;
	z-index: 9900;
	width:100%;
	height: 100%;
	display:none;
}
#layerEnterYourMail {
	position:absolute;
	top: 150px;
	left: 0;
	z-index: 9900;
	width:100%;
	height: 100%;
	display:block;
}
#layerAnswerSMS {
	position:absolute;
	top: 150px;
	left: 0;
	z-index: 9900;
	width:100%;
	height: 100%;
	display:block;
}
#layerForgotYourMail {
	position:absolute;
	left: 0;
	z-index: 9900;
	width:100%;
	height: 100%;
	display:block;
	margin-top:175px;
}

#layerLogin {
	position:absolute;
	top: 15px;
	left: 0;
	z-index: 9900;
	width:100%;
	height: 100%;
	display:block;
}
#layerUnsubCentili {
	position:absolute;
	top: 15px;
	left: 0;
	z-index: 9900;
	width:100%;
	height: 100%;
	display:block;
}
.header_luc{
	width:40%;
}

#layerConfirmAge {
	position:absolute;
	margin-top: 170px;
	left: 0;
	z-index: 9900;
	width:100%;
	height: 1000%;
	display:block;
}

.enter_yourphone{
	width: 100%; 
	background: white;
	margin-bottom:15px;
}

#layerOVER .mid,#layerOVERmarged .mid, #layerOVERExplanation .mid , #layerUnsubFree .mid, #layerEnterYourPhone .mid, #layerEnterYourPhoneV2 .mid, #layerEnterPhoneXXX .mid,#layerEnterPhoneMY .mid, #layerEnterPhoneCA .mid, #layerOperatorPicker .mid,  #layerConfirmAT .mid, #layerUnsubEchovox .mid, #datalayerOVERExplanation .mid, #datalayerOVERExplanation123 .mid{height:100%;margin:0 auto;}
#layerOVER .mid .content,#layerOVERmarged .mid .content, #layerOVERExplanation .mid .content, #layerUnsubFree .mid .content  ,  #layerEnterYourPhone .mid .content  , #layerEnterYourPhoneV2 .mid, #layerEnterPhoneXXX .mid .content  ,#layerEnterPhoneMY .mid .content  , #layerEnterPhoneCA .mid .content  , #layerOperatorPicker .mid .content  ,#layerConfirmAT .mid .content  , #datalayerOVERExplanation .mid .content,#datalayerOVERExplanation123 .mid .content, #layerUnsubEchovox .mid .content  {height:100%;max-width:800px;}
#layerOVER .mid .content .inner, #layerOVERmarged .mid .content .inner, #layerOVERExplanation .mid .content .inner, #layerUnsubFree .mid .content .inner, #layerEnterYourPhone .mid .content .inner, #layerEnterYourPhoneV2 .mid, #layerEnterPhoneXXX .mid .content .inner, #layerEnterPhoneMY .mid .content .inner, #layerEnterPhoneCA .mid .content .inner, #layerOperatorPicker .mid .content .inner, #layerConfirmAT .mid .content .inner, #layerConfirmAge .mid .content .inner, #layerUnsubEchovox .mid .content .inner, #datalayerOVERExplanation .mid .content .inner, #datalayerOVERExplanation .mid .content .inner {height:100%;width:80%;}

#layerLogin .mid .content .inner,#layerUnsubCentili .mid .content .inner, #layerForgotYourMail .mid .content .inner, #layerEnterYourMail .mid .content .inner, #layerAnswerSMS .mid .content .inner {
	height:100%;
	width:95%;
}

.box {
    position:relative;top:0;left:0;
    margin-left:auto;
    margin-right: auto;
    padding:10px 20px 20px 20px;

    overflow:hidden;
}
.contentDetails{
	background:#391a39;
}
.boxHEADER {
    width:100%;
    height:44px;
    background-repeat:no-repeat;
    background-position:50% 0;
}
.boxHEADER_ {
    width:100%;
    background-repeat:no-repeat;
    background-position:50% 0;
}.bonHEADER_adult {
    width:14%;
    background-repeat:no-repeat;
    background-position:50% 0;
}
.slidesjs-container{
	padding-bottom:70px;
}

.slidesjs-pagination-item{
	width:0px;
}
.partner1 .boxHEADER  {
    background-image:url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/logoIG-H60.png');
}
.partner2 .boxHEADER {
    background-image:url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/logo-telefonica.png');
}
.partner3 .boxHEADER {
    background-image:url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/logoVirgin-H60.png');
}
.partner4.boxHEADER {
    background-image:url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/logoBouygues-H60.png');
}
.partner5.boxHEADER {
    background-image:url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/logoBouygues-H60.png');
}
.partner6.boxHEADER {
    background-image:url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/LogoTurkCell.jpg');
}
.box .contenu {
    font-size:1.4em;line-height:1.6em;text-align:left;
}
.box .contenu2 {
    font-size:0.8em;line-height:1.6em;text-align:left;
}
.box .btnCLOSElayerOVER, .box .nextSTEP {
    height: 30px;
    -webkit-border-radius:5px; 
    -moz-border-radius:5px; 
    border-radius:5px;
    padding:0 20px;
    margin: 10px auto 0 auto;
    font-size:1.2em;font-weight:bold;line-height:30px;
    color:#ffffff;    
   
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#443f3f', endColorstr='#0a0809',GradientType=0 );    
    display:inline-block;
}
.box a.btnCLOSEtopright { 
    position:absolute;top:8px;right:8px;z-index:9999;
    margin:0;
    padding:0 0 0 50px;
    background:none;
    font-size:0.9;line-height:0.8em;
    display:block;
}
.box h4 {
    width:100%;
    font-size:1.5em;text-align:center;
}
.box div.step {
    width:90%;
    margin:15% auto;
    padding:0;
}

.box p {
    width:100%;
    font-size:1.2em;text-align:center;
	font-family:robotoregular;
	letter-spacing:.5px;
}
#btnCLOSELAYER {
    position:fixed;top:10px;right:10px;z-index:9000;
    width:20px;height:20px;
    background-color:#000000;
    border:#ffffff 2px solid;
    -webkit-border-radius:50%; 
    -moz-border-radius:50%; 
    border-radius:50%;     
    display:block;
    color:#ffffff;font-size:1.2em;font-weight:bold;line-height:20px;text-align:center;
}
/* FIN POPUP */

/* STRUCTURE COMMUNE */
#wrapSITE {
    width:100%;min-height:100%;
}
#wrapSITElanding {
	height:100%;
    width:100%;min-height:100%;
	background-color: #683653;
}
#wrapSITElanding_2 {
	height:100%;
    width:100%;min-height:100%;
	background-color: white;
}
#wrapSITElanding_BT {
	height:100%;
    width:100%;
	min-height:100%;
	background-color: lightgrey;
	margin-left:auto;
	margin-right:auto;
    font-family: 'Open Sans';
    font-size: 16px;
    color: #464646;
    text-align: center;
    background-color: #ececec;	
}
#wrapSITElanding_2vn {
    width: auto;
    height: auto;
    font-family: Tahoma, sans-serif;
    font-size: 12px;
    margin: auto;
    overflow: hidden;
    background: #341b0d;
}
#wrapSITElanding_3 {
	height:100%;
    width:100%;min-height:100%;
	background-color: black;
}
.mnoHTML{
	background-color:#ececec;
}

#wrapSITElanding_4 {
	height:100%;
    width:100%;min-height:100%;
	background-image:url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/Background_Pleasure.jpg');
    background-repeat: no-repeat;
    /* background-size: 100% auto; */
    /* background-position: center top; */
    background-attachment: fixed;
}

.popupagebox{
    color: black;
    background: white;
    font-weight: bold;
}

#confirmedAge{
	margin:auto;
	width:100%;
	float:right;
	padding-top:5px;
	padding-bottom:5px;
	-webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
	background:#dc3f0d;
}

#confirmedNotAge{
	float:left;
	margin:auto;
	width:100%;

}

#main {
    width:100%;    
	overflow-y: scroll;
	height: 100%;
}
      
#main2 {
    width:100%;    
	overflow-y: scroll;
	height: 100%;
}
#landingMain{
	width:100%;
    height: 90%;
	background-color:black;
	text-align:center;
	font-family:robotoregular;
}
#landingMain_2{
	width:100%;
    height: 90%;
	background-color:#683653;
	text-align:center;
	font-family:robotoregular;
}
#landingMain_3{
	width:100%;
    height: 90%;
	background-color:white;
	text-align:center;
	font-family:robotoregular;
}
#landingMain_BT{
	width:100%;
	background-color:#ececec;
}
.bt_title{
	font-weight:bold;
	font-size:16px;
	color:black;
}
.bt_subtitle{
	color:lightgrey;
	font-size:15px;
}
.bt_button{
	color:white;
	background-color:blue;
	padding:16px;
	border-radius: 5px;
	padding-left: 40px;
    font-weight: bolder;
    font-size: 15px;
    padding-right: 40px;
}
.bt_bottom{
    float: left;
    clear: none;
    display: flex;
	width: 100%;
}
.mno_page_links{
	color:black;
	font-size:11px;
}

/*Start BT dells*/
@import url('https://fonts.googleapis.com/css?family=Open+Sans');


/*** Bouygues Telecom's headband -- Debut ***/
.headband {
    background-color: #fff;
    display: block;
    position: relative;
    width: 100%;
    height: 55px;
	max-width: 560px;
    margin: auto;
	color: black;
}
.headband img {
    width: 30%;
    display: inline-block;
    position: absolute;
    top: 0px;
    left: 10px;
    max-height: 45px;
    max-width: 135px;
    padding: 5px 0 5px 0;
}
.headband div {
    font-family: 'Open Sans';
    font-weight:bold;
    font-size: 14px;
    position: absolute;
    top: 0px;
    left: 30%;
	height: 31px;
	margin-left: 15px;
    padding: 12px 0px 12px 5px;
}
.priceband{
	height:15px;
	background:#ececec;
}
.bottompricemno{
    color: #969595;
    font-size: 10px;
    text-align: left;
}
.descGame{
	margin-top:5px;
	font-size:13px;
	line-height:1.1;
	margin-bottom:5px;
	
}
.divbottompricemno{
	line-height: .5;
    margin-bottom: 10px;
    text-align: left;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
}
/*** Bouygues Telecom's headband -- Fin ***/

div.contentBT {
	max-width: 560px;
    background-color: #fff;
    display: block;
    position: relative;
    width: 100%;
	margin: auto;
}
div.sp_padding {
	height: 100px;
	border-bottom: solid 3px #ececec;
}

/*** Publisher's bloc -- Debut ***/
img.logoediteur {
    width: 57%;
    display: block; /* 画像の下の余白を消す */
	margin-left:auto;
	margin-right:auto;
}
.fieldPhoneWIFIMNO{
	padding:0;
	background-color: #EEEEEE;
    font-size: 14px;
    font-weight: normal;
    border-width: 1px;
    margin-top: 20px;
    padding-top: .425rem;
    padding-bottom: .425rem;	
	height: auto;
    line-height: 1.25;
	width: 85%;
	display: block;
	margin-left:auto;
	margin-right:auto;
	border-radius:0;
	color:#000;
	background-image:none;
	border:1px solid #ccc;
	
}
.service {
    height: 54px;
    padding-top: 10px;
    border-top: solid 3px #ececec;
    border-bottom: solid 3px #ececec;
    font: bold 23px 'Open Sans';
}
.service span {
    color: #6d8082;
}
/*** Publisher's bloc -- Fin ***/


/*** Tariff zone -- Debut ***/
.tariff_z {
    margin-top: 24px;
    font: bold 18px 'Open Sans';
}

/*** Tariff zone -- Fin ***/


/*** Invoiced number zone -- Debut ***/
div.montant-facture {
	font: Normal 12px 'Open Sans';
    border-top: solid 3px #ececec;
    border-bottom: solid 3px #ececec;
    position: relative;
    text-align: center;
    padding: 10px 0 10px 0px;
    height: 38px;
}
/*** Invoiced number zone -- Fin ***/


.zone {
  margin-right: 26px;
  margin-left: 26px;
  font-size: 22px;
  font-weight: bold;
  color:black;
}

/*** Specific conditions -- Debut ***/
#underLayer {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
  background-color: white;
  opacity: 0.60;
}
#overLayer{
  width: 70% ;
  height: 80% ;
  margin: 0 ;
  padding: 10px 20px ;
  border-radius: 10px 10px 10px 10px;
  border: 2px solid #aaa ;
  background: #f5f5f5 ;
  position: fixed ;
  display: none ;
  z-index: 2 ;
  font: normal 9px 'Open Sans';
}
/*** PS-FES **/
#modalOpen {
  font: bolder 14px 'Open Sans';
  background-color: transparent;
  border-width: 0px;
  text-align: left;
  height: 42px;
  width: 100%;
  margin-top: 24px;
  padding-left: 0;
  background-image: url("https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/chevron_right.png");
  background-repeat: no-repeat;
  background-position: right center;
 cursor: pointer;
}
/*** webapp **/
#modalOpen-web {
  font: bolder 14px 'Open Sans';
  background-color: transparent;
  border-width: 0px;
  text-align: left;
  height: 42px;
  width: 100%;
  margin-top: 24px;
  padding-left: 0;
  background-image: url("https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/chevron_right.png");
  background-repeat: no-repeat;
  background-position: right center;
 cursor: pointer;
}
/*** payapp **/
#modalOpen-pay {
  font: bolder 14px 'Open Sans';
  background-color: transparent;
  border-width: 0px;
  text-align: left;
  height: 42px;
  width: 100%;
  margin-top: 24px;
  padding-left: 0;
  background-image: url("https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/chevron_right.png");
  background-repeat: no-repeat;
  background-position: right center;
 cursor: pointer;
}
#modalClose {
  font: bolder 12px 'Open Sans';
  text-decoration: underline;
  cursor: pointer;
  text-align: right;
  position: absolute;
  top: 3%;
  right: 3%;
}
#modal_scroll {
  text-align: left;
  padding-right: 5px;
  position: absolute; /* adjust scroll window */
  top: 10%;
  bottom: 5%;
  left: 5%;
  right: 5%;
  overflow: auto;
}
/*** Specific conditions -- Fin ***/

/*** back zone ***/

/*** PS-FES **/
input.annuler-fleche {
    font: bolder 14px 'Open Sans';
    background-color: transparent;
    border-width: 0px;
    text-align: left;
    height: 42px;
    width: 100%;
    margin-bottom: 24px;
    padding-left: 0;
    background-image: url("https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/cancel_right.png");
    background-repeat: no-repeat;
    background-position: right center;
}
/*** webapp **/
input.annuler-fleche-web {
    font: bolder 14px 'Open Sans';
    background-color: transparent;
    border-width: 0px;
    text-align: left;
    height: 42px;
    width: 100%;
    margin-bottom: 24px;
    padding-left: 0;
    background-image: url("https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/cancel_right.png");
    background-repeat: no-repeat;
    background-position: right center;
}
/*** payapp **/
input.annuler-fleche-pay {
    font: bolder 14px 'Open Sans';
    background-color: transparent;
    border-width: 0px;
    text-align: left;
    height: 42px;
    width: 100%;
    margin-bottom: 24px;
    padding-left: 0;
    background-image: url("https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/cancel_right.png");
    background-repeat: no-repeat;
    background-position: right center;
}

/*** Completion Page ***/
div.service-completion {
    font: Normal 14px 'Open Sans';
    color: #fff;
    -webkit-border-radius: 4px 4px 4px 4px;
    border-radius: 4px 4px 4px 4px;
    background-color: #0b57a2;
    display: flex;
    position: relative;
    width: 100%;
	margin: 8% 0;
	height: 44px;
	border-width: 0px;
	align-items: center;
	justify-content: center;
}

/*** INFORMATION page ***/
div.info {
    display: flex;
	align-items: center;
	justify-content: center;
    height: 64px;
    border-top: solid 3px #ececec;
    border-bottom: solid 3px #ececec;
    text-align: center;
    font: bold 18px 'Open Sans';
    margin-bottom: 15px;
}
/*** PS-FES **/
input.annuler-fleche-info {
    font: bolder 14px 'Open Sans';
    background-color: transparent;
    border-width: 0px;
    text-align: left;
    height: 42px;
    width: 100%;
    padding-left: 0;
    background-image: url("https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/cancel_right.png");
    background-repeat: no-repeat;
    background-position: right center;
}
/*** webapp **/
input.annuler-fleche-info-web {
    font: bolder 14px 'Open Sans';
    background-color: transparent;
    border-width: 0px;
    text-align: left;
    height: 42px;
    width: 100%;
    padding-left: 0;
    background-image: url("https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/cancel_right.png");
    background-repeat: no-repeat;
    background-position: right center;
}
/*** payapp **/
input.annuler-fleche-info-pay {
    font: bolder 14px 'Open Sans';
    background-color: transparent;
    border-width: 0px;
    text-align: left;
    height: 42px;
    width: 100%;
    padding-left: 0;
    background-image: url("https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/cancel_right.png");
    background-repeat: no-repeat;
    background-position: right center;
}

/*** Acces impossible page ***/
div.alert {
    font: Normal 14px 'Open Sans';
    color: #fff;
    -webkit-border-radius: 4px 4px 4px 4px;
    border-radius: 4px 4px 4px 4px;
    background-color: #fe996b;
    display: flex;
    position: relative;
	bottom: -15px;
    width: 100%;
	height: 44px;
	border-width: 0px;
	align-items: center;
	justify-content: center;
	margin-bottom: 15px;
}

/*** With MSISDN transmission ***/

input.number-box {
    font: Normal 14px 'Open Sans';
    background-color: #D3D3D3;
    position: relative;
    width: 100%;
    height: 44px;
	margin-top: 24px;
	margin-bottom: 0px;
	border-width: 0px;
}
/* for Chrome, Safari, Opera */
input.number-box::-webkit-input-placeholder {
    color: #C0C0C0;
    font: Normal 14px 'Open Sans';
}
/* for IE */
input.number-box::-ms-input-placeholder {
    color: #C0C0C0;
    font: Normal 14px 'Open Sans';
}
/* for Firefox ~18 */
input.number-box::-moz-placeholder {
    color: #C0C0C0;
    font: Normal 14px 'Open Sans';
}
/* for Firefox 19~ */
input.number-box::-moz-placeholder {
    color: #C0C0C0;
	opacity: 1;
    font: Normal 14px 'Open Sans';
}

div.err-msg {
	font: Normal 12px 'Open Sans';
	color: #FF0000;
	padding-top: 3px;
	margin-bottom: -15px;
}

input[type=submit]{
  -webkit-appearance: none;
  border-radius: 0;
  cursor: pointer;
}


/***********************************************/
/**************** Existing Page ****************/
/***********************************************/

body.mymenu {
    margin: 10px 10px 10px 10px;
    font-family: arial, sans-serif;
    font-size: 16px;
    color: #464646;
    text-align: center;
    background-color: #ececec;
}


hr {
    border-top: #cdcdcd;
    border-width: 1px;
    border-top-style: solid;
}

input.annuler {
    font-size: 100%;
    margin-top: 5%;
    background-color: transparent;
    border-width: 0px;
	color: #464646;
	margin-bottom: 5%;
}

div.texte-principal {
    font-size: 100%;
    margin-top: 16%;
    margin-bottom: 15%;
}

div.content-menu {
    background-color: #fff;
    display: block;
    position: relative;
    width: 100%;
    padding-top: 11%;
}
div.service-menu {
    font-weight: bold;
}
div.tarif {
    font-weight: bold;
	    margin-top: 4%;	
}

.titre-service {
    font-weight: bold;
    color: #fff;
    background-color: #009ccc;
    display: inline-block;
    position: relative;
    width: 100%;
	margin-top: 8%;
	    padding-top: 6%;
    padding-bottom: 6%;
	    border-width: 0px;
		    font-size: 100%;
}

img.logobytel001 {
    width: 40%;
    display: inline-block;
    position: relative;
}

input.bt-valider-small {
    font-weight: bold;
    color: #fff;
    -webkit-border-radius: 4px 4px 4px 4px;
    border-radius: 4px 4px 4px 4px;
    background-color: #0b57a2;
    display: inline-block;
    position: relative;
    width: 45%;
    margin-top: 4%;
    padding-top: 5%;
    padding-bottom: 5%;
    border-width: 0px;
    font-size: 100%;
}
input.bt-refuser-small {
    font-weight: bold;
    color: #464646;
    -webkit-border-radius: 4px 4px 4px 4px;
    border-radius: 4px 4px 4px 4px;
    background-color: #ececec;
    display: inline-block;
    position: relative;
    width: 45%;
    margin-top: 4%;
    padding-top: 5%;
    padding-bottom: 5%;
    border-width: 0px;
    font-size: 100%;
}

div.defaut-sans-margin {
    margin-top: 5%;
}

div.deux-col {
    width: 100%;
    display: block;
    position: relative;
	    margin-top: 4%;
}

div.col-gauche-text {
    width: 45%;
    text-align: left;
    display: inline-block;
    position: relative;
    margin-left: 0.5rem;
    font-weight: bold;
	float: left;
}
div.col-droite-text {
    width: 45%;
    text-align: right;
    display: inline-block;
    position: relative;
    margin-right: 0.5rem;
    font-weight: bold;
}

input.annuler-fleche-menu {
    font-size: 100%;
    margin-top: 5%;
    background-color: transparent;
    border-width: 0px;
    color: #464646;
    margin-bottom: 5%;
    color: black;
    background-image: url("https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/chevron_right.png");
    background-repeat: no-repeat;
    background-position: right center;
    width: 90%;
}

.cliquerici {
    font-weight: bold;
    text-decoration: underline;
}
.mystreaming6_playerCentral{
	width:100%;
	line-height: 0;
}
.mystreaming6_head{
	width: 100%;
	background-color:#e8e9ed;
    height: 54%;
    padding-top: 10px;
}
.mystreaming7_head{
	width: 100%;
	background-color:white;
    padding-top: 10px;
}
.mystreaming8_head{
	width: 100%;
	background-color:#525868;
    padding-top: 10px;
}
.mystreaming6_headsplitt{
	display: inline-table;
    height: 158px;
}
.mystreaming7_headsplitt{
	display: inline-table;
    height: 79px;
}
.mystreaming6_title{
    margin-left: auto;
    margin-right: auto;
	background-color:#dedfe3;
	padding-top:17px;
	
	height:46%;
}
.mystreaming6_vignette_link{
}
.mystreaming6_img{
	width:60%;
}
.mystreaming7_img{
	width:90%;
}
.mystreaming6_playerLeft{
	width:50%;
	float:left;
	line-height: 0;
}
.mystreaming6_playerRight{
	width:50%;
	float:right;
	background-color:#f5f5f5;
}
.mystreaming6_cta{
	line-height:0;
}

.mystreaming6_player{
display:flex;
}
.mystreaming6_playerRight_text1{
	font-size:4.5vw;
	font-family: 'Arvo', sans-serif;
}
.mystreaming6_playerRight_text2{
    font-size: 2vw;
    letter-spacing: 1px;
    font-family: 'Montserrat', sans-serif;
	color:gray;
}
.mystreaming6_playerRight_text3{
    font-size: 2.9vw;
    color: #9e9e9eeb;
	font-family: 'Montserrat', sans-serif;
	line-height:190%;
	
}
.inside_rightplayer{
	text-align:left;
	padding: 10px;
    padding-top: 13px;
    padding-bottom: 13px;
	max-height:125px;
}
.mystreaming6_span{
    letter-spacing: 3px;
	font-size:8px;
	color:#a0a3a8;
	font-family: 'Montserrat', sans-serif;
	font-style:italic;
}
.mystreaming6_span_title{
	font-size:5vw;
    font-family: 'Arvo', serif;
}
.center60div{
	width:60%;
	margin-left:auto;
	margin-right:auto;
}
.mystreaming6_span_title2{
	font-size:2.5vw;
	color:#626367;
	font-family: 'Montserrat', serif;
}
.mystreaming6_span_subtitle{
	font-size:9px;
}
.mystreaming6_vignette{
	width:100%;
}
.mystreaming6_vignettes{
	display: flex;
	line-height:0;
}
.mystreaming6_footer_pic{
	width:100%;
}

.mystreaming6_footer_left{
	width:25%;
    display: -webkit-inline-box;
    padding: 0px;
	padding: 25px;
    padding-left: 0px;
    padding-right: 0px;
}
.mystreaming8_footer_center{
    width: 40%;
    padding: 0;
    padding: 5px;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
}
.mystreaming7_footer_center{
    width: 35%;
    padding: 0;
    padding: 21px;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
}
.mystreaming7_footer_center2{
	width:100%;
    display: -webkit-inline-box;
	padding-left:0;
	padding-right:0;
	padding-bottom:65px;
	margin-left:auto;
	margin-right:auto;
	line-height:9px;
}
.mystreaming6_footer{
	padding:10px;
    background: #212121;
    display: -webkit-box;
	height:35vh;
}
.mystreaming7_footer{
    background: #212121;
    display: -webkit-box;
	text-align:left;
}
.mystreaming8_footer{
    background: white;
    display: -webkit-box;
	text-align:left;
}
.mystreaming7_footer2{
    background: #404040;
    display: -webkit-box;
	text-align:left;
}
.mystreaming8_footer2{
    background: white;
    display: -webkit-box;
    text-align: left;
    bottom: -50px;
    position: absolute;
    margin-left: 0;
    margin-right: 0;
    left: 6%;
    right: 6%;
}.mystreaming9_footer2{
    background: white;
    display: -webkit-box;
    text-align: left;
    margin-left: 0;
    margin-right: 0;
    left: 6%;
    right: 6%;
}
.mystreaming8_footer2before{
    background: white;
    display: -webkit-box;
	text-align:left;
}
.mystreaming6_footer_right{
	width:70%;
	float: right;
    line-height: 7px;
    font-size: 10px;
    text-align: left;
}
div.col-gauche {
    width: 48%;
    text-align: right;
    display: inline-block;
    position: relative;
    padding-right: 6px;
}
div.col-droite {
    width: 48%;
    text-align: left;
    display: inline-block;
    position: relative;
    font-weight: bold;
}
div.defaut-small-sans-margin {
    margin-top: 5%;
    font-size: 90%;
    padding-bottom: 5%;
}


/***********************************************/
/**************** SAPview Page *****************/
/***********************************************/


body.myservice {
	padding: 0;
	margin: 0;
	color: #0A3448;
	background-color: #dcdcdc;
	text-decoration: none;
	text-decoration: none;
	font-family: arial;
	margin: 0;
	padding: 0;
	font-size: 13px;
	border-style: none;
	border-width: 0;
}
.s164 {
	background-color: #FFFFFF;
	padding: 5px 0 4px 0;
	border-top-style: solid;
	border-top-color: #FFFFFF;
	border-top-width: 1px;
	margin: 0;
}
.s168 {
	color: #00abc5;
	font-weight: bold;
	margin: 15px 0 0 0;
	border-color: #FFFFFF;
	padding: 0 2px 0px 1px;
}
.s171 {color: #FFFFFF;}
.s178 {padding: 0 0 0 5px;}
.s184 {
	color: #FF6600;
	font-size: 12px;
}
.s187 {
	text-align: center;
	vertical-align: middle;
}
.s221 {
	text-align: right;
	display: inline;
	float: right;
	height: 30px;
}
.s473 {
	padding: 0 0 0 5px;
	background-color: #A7ABAC;
}




/*End BT delss*/

.bt_bottom_right{
	margin: auto;
	width: 50%;
	color:grey;
}
.bt_bottom_left{
	margin: auto;
	width: 50%;
	color:red;
}
	
#landingMain_3vn{
	max-width:650px;
	background-color:white;
	text-align:center;
	font-family:robotoregular;
    background: url(https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/backgroundVN2.jpg);	
}
#landingMain_3t{
	width:100%;
    height: 90%;
	background-color:transparent;
	text-align:center;
	font-family:robotoregular;
}
.backgroundAppTv{
	background: #FFFFFF url(https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/bg_bps_1.jpg);
	background-position: center top;
	background-size: cover;
	background-repeat: no-repeat;
}
.backgroundAppTv2{
	background: #FFFFFF url(https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/bg_bps_2.jpg);
	background-position: center top;
	background-size: cover;
	background-repeat: no-repeat;
}
.transparentBG{
	background-color:transparent;
}
#landingMain_4{
	width:100%;
    height: 90%;
	background-color:black;
	text-align:center;
	font-family:robotoregular;
}
#landingMain_5{
	width:100%;
    height: 90%;
	background-image:url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/Background_Pleasure.jpg');		
    background-repeat: no-repeat;
    /* background-size: 100% auto; */
    /* background-position: center top; */
    background-attachment: fixed;
	text-align:center;
	font-family:robotoregular;
}
.landingMainContent{
	width:93%;
    margin-left: auto;
    margin-right: auto;
	display:block;
}

.ch_adult_button{
	z-index:99;
	position:fixed;
	background:transparent;
	bottom: 30%;
}
.swisscompage_url_lp{
	color:white;
	background:black;
	padding:5px;

}  
.landingMainContent_2{
	width:100%;
    margin-left: auto;
    margin-right: auto;
	display:block;
}
.landingContentStream{
	width:100%;
    margin-left: auto;
    margin-right: auto;
	display:block;
	text-align:center;
	background:white;
	color:black;
    max-width: 545px;
	
}
.ci_10_title_div{
	width:80%;
	color:white;
	text-align:center;
	margin-left:auto;
	margin-right:auto;
	letter-spacing:1.8px;
}
.ci_10_title_span{
	font-family:robotoregular;
	font-size:28px;
	color:white;
	font-weight:bold;
	line-height:1.1;
}
hr.ci_10_hr{	
    border: 1px solid #FC7D0A;
    width: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
}
.ci_10_subtitle_div{
	width:80%;

	color:white;
	text-align:center;
	margin-left:auto;
	margin-right:auto;
}
.ci_subtitle_span{
	font-size:16px;
	color:white;
}
.ci_10_cta{
	color:white;
	background-color:green;
	padding-left:20%;
	padding-right:20%;
	padding-top:5%;
	padding-bottom:5%;
	-webkit-border-radius:3px; 
	-moz-border-radius:3px; 
	border-radius:3px; 	
}
.backgroundv2{
    background-color: #e8e9ed;
	margin-top: -5px;
}
.backgroundv3{
    background-color: white;
	margin-top: -5px;
}
.black{
	background-color: black;
}	
.head_rdc_stream{
	font-weight:bold;
	font-size:14px;
}
.rdc_popup_enjoy{
    font-size: 13px;
    color: black;
	font-weight: bold;
}
.rdc_popup_enjoy_div{
	margin-top:15px;
	margin-bottom:15px;
}
.rdc_popup_header{
	background: #424242;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
    font-size: 15px;
    font-weight: bold;
}
.rdc_popup_footer{
	margin-bottom: 30px;
    margin-top: 10px;
}
.rdc_enterphone_phrase{
	font-size:10px; 
	letter-spacing: 1px;
    color: black;
    font-weight: bold;	
}
.head_rdc_simple_big_div {
	background-color:#5cb4ff;
    text-align: left;
    padding-bottom: 5px;	
}
.divBoutonGaming{
	width:80%;
	margin-left:auto;
	margin-right:auto;
	
}

.header_gaming_lp{
	color:black;
	text-align:center;
	font-weight:bold;
	font-size:15px;
}
.header_gaming_lp_gray{
	color:gray;
	text-align:center;
	font-size:15px;
}
.header_gaming_lp0{
	color:black;
	text-align:center;
	font-size:15px;
}

.header_gaming_lp2{
	color:black;
	text-align:center;
	font-weight:bold;
	font-size:25px;
}

.head_rdc_stream_big_div{
	font-weight:bold;
	font-style:italic;
	font-size:18px;
	letter-spacing:1px;
	margin-top:20px;
	margin-bottom:10px;
}
.header_rdc_stream_player_pic{
	width:100%;
}
.header_rdc_stream_player_pic2{
	width:90%;
	margin-left:auto;
	margin-right:auto;
}
.header_rdc_stream_player_pic2{
	width:100%;
}
.header_rdc_buying_pic{
	width:100%;
}

.center_rdc_stream_title{
    margin-top: 15px;
    margin-bottom: 15px;
	font-size:13px;
	color:#525252;
	font-weight: bold;
}
.center_rdc_stream_title2{
	text-align:center;
	font-size:10px;
	color:grey;
    font-size: 8px;
    color: grey;
    line-height: 1px;	
}

.center_rdc_stream_title3{
	color: black; 
	font-size:11px;
	letter-spacing:0.01px;
	text-align:center;
}
.center_rdc_stream_title4{
	text-align:center;
	font-size:10px;
	color:black;
    font-size: 8px;
    color: black;
    line-height: 1px;	
}
.ci_footer{
	left:0%;
	right:0%;
	padding-top:17%;
}
.center_rdc_stream_title5{
	text-align:center;
	color:black;
    font-size: 14px;
    line-height: 1px;	
}
.head_rdc_stream_player2{
	margin-top:10px;
	margin-bottom:10px;
}
.head_rdc_stream_player3{
	margin-top:10px;
	margin-bottom:10px;
	padding-left:5px;
	padding-right:5px;
}
.head_rdc_stream_player{
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}
.center_rdc_stream_title2_div{
	width:95%;
	margin-left:auto;
	margin-right:auto;
	
}
.center_rdc_stream_pic{
	width:70%;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:35px;
}
.center_rdc_stream_pic2{
	width:60%;
	margin-left:auto;
	margin-right:auto;
}
.centered_3_pics{
	width:95%;
	margin-left:auto;
	margin-right:auto;
}
.centered_1_pic{
  float: left;
  width: 33.33%;
}

.centered_3_pics::after {
  content: "";
  clear: both;
  display: table;
}
.center_rdc_stream_picture{
	width:100%;
}

.landingMainContent_BT{
	width:96%;
    margin-left: auto;
    margin-right: auto;
	display:block;
	background-color:white;
	max-width: 560px;
}
.landingMainContent_3{
	width:100%;
    margin-left: auto;
    margin-right: auto;
	display:block;
    background-image: url(https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/Background_Pleasure.jpg);
    background-repeat: no-repeat;
    /* background-size: 100% auto; */
    /* background-position: center top; */
    background-attachment: fixed;
}
.macaron_middle{
	width: 90%;
    margin: auto;
	display: inline-block;
}
.macaron_left{
	float:left;
	width:75%;
	margin-top: 3%;
	text-align:center;
}
.macaron_right{
	float:right;
	width:25%;
}
.macaron_itself{
	width:74%;
	padding-top: 2px;
}
.landingLeftArrow{
	width: 12%;
	float: left;
	visibility:hidden;
}
.landingSmartphone{
	width: 12%;
	float: left;
    position: absolute;
}
.landingRightArrow{
	width: 12%;
	float: right;
	visibility: hidden;
}
.landingPrice{
	width:100%;
	text-align:center;
	color:white;
	background-color:grey;
	padding-top:5px;
	padding-bottom:5px;
	font-size: 1.5em;
}
.landingPrice_4{
	width:100%;
	text-align:center;
	color:black;
	background-color:lightgrey;
	padding-top:5px;
	padding-bottom:5px;
	font-size: 1.5em;
}
.landingPrice_2{
	width:100%;
	text-align:center;
	color:black;
	background-color:lightgrey;
	padding-top:5px;
	padding-bottom:5px;
	font-size: 1.6em;
}.landingPrice_2SMS{
	width:100%;
	text-align:center;
	color:grey;
	background-color:lightgrey;
	font-size: 1.15em;
}.landingPrice_3{
	width:100%;
	text-align:center;
	color:grey;
	background-color:lightgrey;
	padding-top:4px;
	padding-bottom:4px;
	font-size: 1.6em;
}
.landingPrice_2_BE{
	width:100%;
	text-align:center;
	color:grey;
	background-color:lightgrey;
	padding-top:5px;
	padding-bottom:5px;
	font-size: 1.5em;
}
.landingTitle{
    font-size: 2.2em;
    letter-spacing: 1.6px;
    color: white;
    font-weight: bold;
    width: 100%;
}
.landingTitle_2{
    font-size: 1.4em;
    letter-spacing: 1.6px;
    color: white;
    font-weight: bold;
    width: 100%;
}
.landingTitleDiv{
    padding-top: 13px;
    padding-bottom: 6px;
}
.landingTitleDivLeft_2{
	
}
.landingTitleRight_2{
    padding: 5px;
    width: 45%;
    float: right;
    height: 100%;
    font-size: 1.2em;
    font-weight: bold;
    height: 47px;
    line-height: 55px;
}
.landingHeaderBgames_2{
	width: 45%;
	float: left;
	padding: 5px;	
}

.landingTitle2{
	font-size:1.4em;
	color:white;
	width:100%;
	padding-top:3px;
	padding-bottom:3px;
}
.landingSubTitle{
	font-size:1em;
	color:white;
	width:100%;
	padding-top:3px;
	font-weight:bold;
	padding-bottom:3px;
}
.landingTitle2ADW{
	font-size:2em;
	color:#00ff34;
	width:100%;
	padding-top:3px;
	padding-bottom:3px;
}
.itframe{
	width:100%;
	height:225px;
}
.specialbtn{
	background:red;
	border-radius:0;
}
.specialtxt{
	border-radius:0;
}
.marged{
	margin-left:auto;
	margin-right:auto;
}
.titledivlaunchvideo{
	color:white;
	font-size:23px;
	font-weight:bold;
	width:100%;
	letter-spacing:2px;
}

.landingTitle2ADW_2{
	font-size:0.9em;
	color:white;
	width:100%;
	padding-top:3px;
	padding-bottom:3px;
}
.landingSubTitleADW{
	font-size:1em;
	color:white;
	width:100%;
	padding-top:3px;
	font-weight:bold;
	padding-bottom:3px;
	letter-spacing:1.5px;
}
.landingSubTitleADW_2{
	font-size:1.4em;
	color:black;
	width:100%;
	padding-top:3px;
	font-weight:bold;
	padding-bottom:3px;
	letter-spacing:1.2px;
}.landingSubTitleADW_10{
	font-size:1.4em;
	color:#404746;
	width:100%;
	padding-top:3px;
	font-weight:bold;
	padding-bottom:3px;
	letter-spacing:1.2px;
}.landingSubTitleADW_3{
	font-size:0.9em;
	color:black;
	width:100%;
	padding-top:3px;
	font-weight:bold;
	padding-bottom:3px;
	letter-spacing:1.2px;
}
.landingSubTitleADW_4{
	font-size:13px;
	color:black;
	width:100%;
	padding-top:3px;
	font-weight:bold;
	padding-bottom:3px;
	letter-spacing:1px;
}

.landingLittlePicture{
	width:32%;
	padding:1px;
}
.landingLittlePicture_2{
	width:23%;
	padding:1px;
}
.landingLittlePicture_2BE{
	width:35%;
	padding:1px;
}
.landingBottom{
	width:90%;
	margin-left: auto; 
	margin-right: auto;
    padding-top: 10%;
    padding-bottom: 10%;	
}
.landingBottom_2{
	width:95%;
	margin-left: auto; 
	margin-right: auto;
    padding-top: 6%;
    padding-bottom: 6%;	
}
.landingMainPicture{
	width:100%;	
}
.landingMainPictureArrow{
	width:100%;
	position: absolute;
    left: 0px;
    top: 0px;
	visibility:hidden;
	margin-top:50px;
}
.landingMainPictureDiv{
	padding-top:3px;
	padding-bottom:3px;	
	margin-bottom: 6px;
	position: relative;
}
.imgLPheader{
	margin-left:auto;
	margin-right:auto;
	width:30%;
}
.landingMainPictureDiv_2{
	padding-top:3px;
	padding-bottom:3px;	
	margin-bottom: 10px;
	position: relative;
}
.landingFormDiv{
	width:100%;
}
.landingFormBT{
	margin-top:20px;
	width:100%;
}
.landingForm{
	width:97%;
	margin:0;
	padding-bottom:0;
	margin-bottom:10px;
	margin-top:6px;
	position:relative;
	
}
.margintop5{
	margin-top:5px;
}
.landing3gOKbutton{
	border-radius:7px; 
	width: 100%;
	margin:0;
	background: yellow;
	color:black;
	font-weight:bold;
    display: block;
    margin-top: 5px;
    font-size: 2.4em;
	height:54px;
	line-height:54px;
}
.operator{
	font-size: 1em;
	width:100%;
}
.checkBEform{
	padding:2px;
}
.landingPhoneField{
	font-size: 1em; 
	border-radius:7px;
	width: 70%; 
	background: white; 
	padding:2%;
	margin-bottom:0px;
}
.labelTOPsimpleLP{
	color: black;
	font-size: 0.8em;
	margin-top: 3px;
	    background-color: white;
}
.landingPhoneField_2{
	font-size: 1em; 
	border-radius:7px;
	width: 66%; 
	background: lightgray; 
	padding:1%;
	margin-bottom:0px;
}.formCustomInput1{
	padding:1%;
	margin-bottom:0px;
	border-radius:0px;
	width:88%;
	background-color:white;
	font-size:30px;
	height: 28px;
	margin-bottom: 75px;
	
}
#content_ca #FKP .ctrl_play .inProgress {
    height: 9px;
    background: red;
    animation-name: prog;
    animation-duration: 20s;
    width: 35%;
}
.nopad{  
	padding:0px;
}
.csslame{
	width: 34px;
}
.testBtn{
	position: absolute;
    margin-top: -12px;
    margin-left: 29%;

}
.pricepoint{
	font-size:12px;
	font-weight:normal;
	color:black;
	text-align:center;
}
#content_ca{
	max-width: 960px;
    margin: auto;
    padding-top: 1%;
    padding-bottom: 1%;
}
#content_ca #FKP {
    margin: auto;
}
#content_ca #FKP {
	background-image:url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/ca_testpic.jpg');
	background-size: 100% auto;
    /* background-position: center top; */
    margin: auto;
    width: 100%;
    position: relative;
    padding-bottom: 2%;
}
#content_ca #FKP .playCA {
    top: 18%;
}
#content_ca #FKP .playCA {
    display: block;
    z-index: 2;
    position: absolute;
    width: 10%;
    top: 30%;
    left: 45%;
    text-align: center;
    color: #fff;
    border-radius: 10px;
}
#content_ca #FKP .loadingCA {
    top: 25%;
}
#content_ca #FKP .loadingCA {
    display: block;
    z-index: 2;
    position: absolute;
    width: 10%;
    top: 30%;
    left: 45%;
    text-align: center;
    color: #fff;
    border-radius: 10px;
}
#content_ca #FKP .ctrl_play {
    display: block;
    z-index: 2;
    position: relative;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 48px;
    background: black;
    padding-bottom: 11px;
    padding-top: 11px;
	margin-bottom:10px;
}
#content_ca #FKP .ctrl_play {
    height: 25px;
}
#content_ca #FKP .ctrl_play .ctrl_btn img{
	opacity: 0.75;
    width: 29% !important;
    height: auto !important;
	
}
.blocHelpUnsub{
	font-size:17px;
}
.MNOSelect span{
	font-size:19px;
	text-align:center;
}
.MNOSelect{
	width: 100%;
	text-align:center;
}
.MNOSelect h2{
	font-size:25px;
	text-align: center;
	margin-right: auto;
	margin-left: auto;
}
.MNOSelect img{
	width: 27%!important;
    height: auto!important;
    padding: 5px;
}
#content_ca #FKP .ctrl_play .progress_barre {
    height: 9px;
    background: #AAA;
    width: 100%;
}
#content_ca #FKP .ctrl_play .temp {
    color: white;
    font-size: 15px;
}
.loadingCA img{
	vertical-align:text-top;
}
.playCA img{
    max-height: 15vw;
	vertical-align:text-top;
}
#content_ca #fullBlocks {
    margin-bottom: 2%;
    margin-top: 2%;
    text-align: center;
}
#content_ca #fullBlocks .bottomBlocks {
    color: #FFF;
    font-weight: bold;
	background: #252429;
	font-size:16px;
}
.valign{
	vertical-align:text-bottom;
}
#fullBlocks .bottomBlocks {
    line-height: 50px !important;
    width: 96% !important;
    float: none !important;
    margin-bottom: 1% !important;
    margin: auto;
}
#content_ca #fullBlocks .bottomBlocks a {
    text-decoration: none;
    color: #DDD;
    font-weight: bold;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#content_ca #FKP:after {
    padding-top: 56.25%;
    display: block;
    content: "";
}

.playButtonCA{
    font-family: robotoregular;
    position: absolute;
    right: 0;
    margin-right: 10%;
    margin-top: -14%;
    padding-top: 5px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 25px;
    color: white;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    border-radius: 24px;
    background-color: #ff0000;
    font-size: 29px;
    letter-spacing: 1px;
}
.landingPhoneField_3{
	font-size: 19px; 
	border-radius:2px;
	width: 80%; 
	background: lightgray; 
	padding-left:0px;
	padding-right:0px;
	padding-top:6px;
	padding-bottom:6px;
	letter-spacing:0.1px;
	margin-bottom:0px;
	text-align:center;
}
.landingMailField{
	font-size: 1em; 
	border-radius:7px;
	width: 100%; 
	background: lightgray; 
	padding:2%;
	margin-bottom:0px;
}
.landing3gOKbutton_2{
	height: 47px;
	border-radius:7px;
	width: 97%;
	margin: 0;
	background:#00ff36;
	color:black;
	font-weight:bold;
	padding: 0px;
	margin-top: 5px;
}
.landing3gOKbutton_4{
	height: 47px;
	border-radius:7px;
	width: 80%;
	margin: 0;
	background:#683653;
	color:black;
	font-weight:bold;
	padding: 0px;
	margin-top: 5px;
}
.picZALP{
	width:60%;

}
.landingOKButton_noPic{
    height: 100%;
    border-radius: 0;
    width: 80%;
    margin: 0;
    background: #c50001;
    color: white;
    font-weight: bold;
    font-size: 19px;
    letter-spacing: 0.1px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.landingOKButton_noPic_2{
	height: 100%;
    border-radius: 0;
    width: 80%;
    margin: 0;
    background: #c50001;
    color: white;
    font-weight: bold;
    /* padding: 11px; */
    font-size: 36px;
    margin-top: 1px;
    letter-spacing: 3px;
    padding-top: 20px;
    padding-bottom: 20px;
}
.landing3gOKbutton_3{
	height:100%;
	border-radius:0px;
	width: 70%;
	margin: 0;
	background:transparent;
	color:black;
	font-weight:bold;
	padding: 0px;
	margin-top: 5px;
}
.landing3gOKbutton_5{
	height:100%;
	border-radius:0px;
	width: 70%;
	margin: 0;
	background:transparent;
	color:black;
	font-weight:bold;
	padding: 0px;
}
.okbuttonXXX{
	width:71%;
}
.menuTerms{
    text-align: center;
    padding: 5px;
    margin-bottom: 10px;
}
.landingtopLink{
	text-align:center;
	margin:0.5rem auto;
}

.landingScrollContent{
	position: relative;
	padding-left:30px;
	padding-right:30px;
    color: #808080;
    font-size: 12px;
	text-align: justify;
	background:#683653;
}
.landingScrollContent_t{
	position: relative;
	padding-left:30px;
	padding-right:30px;
    color: #808080;
    font-size: 12px;
	text-align: justify;
	background:transparent;
}
.landingScrollContentPortail{
	position: relative;
	padding-left:30px;
	padding-right:30px;
    color: white;
    font-size: 12px;
	text-align: justify;
	background:#391a39;
}
.landingScrollContentPortail2{
	position: relative;
	padding-left:30px;
	padding-right:30px;
    color: white;
    font-size: 14px;
	text-align: justify;
	background:#391a39;
}
.landingScrollContent_2{
	position: relative;
	padding-left:30px;
	padding-right:30px;
    color: #808080;
    font-size: 12px;
	text-align: justify;
	background:white;
}
.landingScrollContent_3{
	position: relative;
	padding-left:30px;
	padding-right:30px;
    color: #808080;
    font-size: 12px;
	text-align: justify;
	background:black;
}
.backgroundfixxx{
	background-position: center center;
	background-attachment: fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover !important;


	
}
.landingScrollContent_4{
	position: relative;
	padding-left:30px;
	padding-right:30px;
    color: #808080;
    font-size: 12px;
	text-align: justify;
	background-image:url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/Background_Pleasure.jpg');
    background-repeat: no-repeat;
    /* background-size: 100% auto; */
    /* background-position: center top; */
    background-attachment: fixed;
}
.landingScrollContent a {
	color: #808080;
    text-decoration: underline;	
}

#mainHEADER {
z-index:1;
height:8.5%;max-height:140px;overflow:hidden;}
#mainHEADER .mid {}
#mainHEADER .mid .content {
	padding-left:18px;
	max-width:800px;
	position:fixed;
	backface-visibility: hidden;


	z-index:9999;
	background-color:#683653;
}


.header_left{	
	width:10%;
	float:left;
	text-align:left;
	padding-top:5px;
}

.header_right{
	width:88%;
	text-align:left;
	
	float:right;
	font-size:1em;  
}


/* SWITCH LANGUES */
    #switchLANGUAGE {
        position:absolute;top:0;left:0;z-index:1001;
        width:100%;
        text-align:right;    
    }
    #switchLANGUAGE .mid {}
    #switchLANGUAGE .mid .content {max-width:800px;}
    #switchLANGUAGE .mid .content ul {padding-right:20px;text-align:right;}
    #switchLANGUAGE .mid .content ul li {
        float:left;
        margin-left:6px;
    }
    #switchLANGUAGE .mid .content ul li a {
        width: 24px;height:24px;
        background-position:0 0;
        background-repeat:no-repeat;
        background-size: cover;
        display: block;
    }
    #switchLANGUAGE .mid .content ul li a.EN {
        background-image: url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/flag_EN.png');
    }    
    #switchLANGUAGE .mid .content ul li a.ES {
        background-image: url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/flag_ES.png');
    } 
    #switchLANGUAGE .mid .content ul li a.FR {
        background-image: url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/flag_FR.png');
    }
/* FIN SWITCH LANGUES */

.cancelPTHeader{
	text-align: center;
    position: fixed;
    z-index: 10000;
    width: 100%;
	font-size:14px;
	padding-top:2px;
}
#mainLOGO {
    width:100%;
    margin:0;
    padding:12px 0;
    display:block;
}
#mainLOGO img.logo {
    width:90%;
    display:block;
}
#mainLOGO img.logo2 {
	width:40%;
}

#titlePAGE {
    margin:0 0 20px 0;
}
#titlePAGE .mid {}
#titlePAGE .mid .content {max-width:800px;}



/* MENU CATEGORIE */
#navCAT {
    padding:10px 0;
    filter: dropshadow(color=#000000, offx=0, offy=0);
}
#navCAT .mid {}
#navCAT .mid .content {
    max-width:800px;height:58px;
    overflow:hidden;
}
#navCAT ul {
    width:auto;height:32px;
    margin:13px 0;
    overflow:hidden;
}
#navCAT ul li.btn {
    height:32px;
    -webkit-border-radius:5px; 
    -moz-border-radius:5px; 
    border-radius:5px;
    padding:0 8px;
    margin:0 8px;
    float:left;
    font-size:1.4em;line-height:32px;
}
#navCAT ul li.btn a {
    display:block;
}           
.navSECTION {
    position:fixed;bottom:120px;left:0;
    width:100%;
}
.navSECTION .mid {}
.navSECTION .mid .content {
    max-width:800px;
} 
/* FIN MENU CATEGORIES */

.rightalign{
text-align:right;
margin:-10px auto 10px auto;
}
     
/* SECTION DETAIL D'UN JEU' */
.detailSECTION {}
.detailSECTION .mid {}
.detailSECTION .mid .content {
    max-width:800px;
    min-height:150px;
    overflow:hidden;
    outline:none;
}
    /* GAMES : DETAILS SECTION INFOS */
    .detailSECTION .tab-pane {}            
    .detailSECTION {} 
    .detailSECTION .headerSECTION {
       position:relative;top:0;left:0;
       margin:0 auto 4px auto;
       overflow:hidden;
    }      
    .detailSECTION .headerSECTION .picto {
        width:25%;max-width:100px;
		float:left;
		padding-right:8px;
    } 
    .detailSECTION .headerSECTION .editoGAME {
		float:left;
		margin-top:4px;
		text-align: left;
		width: 46%;


	}
    .detailSECTION .headerSECTION h2 {
        position:absolute;top:0px;left:116px;
        max-width:650px;
        font-size:2.4em;text-align:left;
        text-transform:uppercase;
    }     
	.contentGAME{
		background:#391a39;
		background-color:#391a39;
		height:100%;
	}
	.subtitle{
		padding-left:18px;
		padding-right:18px;
		font-weight:400;
		padding-top:15px;
		padding-bottom:15px;
		color:#ffffff;
		font-size:1.4em;
		letter-spacing:0.3px;
		text-align:left;
		font-family:robotomedium;
		
	}
    .detailSECTION .headerSECTION .btnACTION {
		font-family:robotoregular;
        position:absolute;top:0px;right:0px;
        min-width:80px;height: 30px;
        -webkit-border-radius:4px; 
        -moz-border-radius:4px; 
        border-radius:4px;
        padding:0 10px;
        font-size:1.1em;font-weight:bold;line-height:30px;
        color:#ff7c67;    
		top:22px;
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#443f3f', endColorstr='#0a0809',GradientType=0 );
    }       
    .detailSECTION #headerGAME .btnACTION:hover {
        background:#ffffff;
        color:#000000;
    }
    .detailSECTION #headerGAME #btnPLAY {}          
    .detailSECTION #headerGAME #btnACTION {}           
    .detailSECTION #infosGAME #longDES, .detailSECTION #infosGAME #longDESshowed, .detailSECTION #infosGAME #longDEShidden {
        text-align:left;
    }                       
    .detailSECTION .longDES, .detailSECTION .longDESshowed, .detailSECTION .longDEShidden {
        font-size:1.1em;line-height:1.4em;text-align:left;
		padding-left:18px;
		padding-right:18px;
    }                   
    .detailSECTION #leaderboardGAME {}
    .detailSECTION #leaderboardGAME #enteteTABLE {background-color:rgba(0,0,0,0.2);}

    .detailSECTION #screenshootGAME{}
    .detailSECTION #screenshootGAME .rg-thumbs .es-carousel img {width:50px;cursor:pointer;}
    .detailSECTION #screenshootGAME .rg-image-wrapper .es-carousel img {height:100%;}




	/* FIN SECTION DETAIL */



/* MASK LIST */
#maskLIST {
    position:fixed;bottom:100px;left:0;z-index:5000;
    width:100%;height:50px;
}
#maskLIST .mid {}
#maskLIST .mid .content {
    max-width:960px;height:50px;
    background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,0.65)));
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
    background: -o-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
    background: -ms-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
    background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
}
/* FIN MASK LIST */


/* MAIN FOOTER */
#mainFOOTER {
    bottom:0;
	left:0;
	z-index:5000;
    width:100%;
	height:100px;
}
#mainFOOTER .mid {}
#mainFOOTER .mid .content {
    max-width:960px;
}
#mainFOOTER #mainNAV {}
#mainFOOTER #mainNAV ul {
    position:relative;top:0;left:0;
    width:100%;height:100px;
    background:#000000;
}
#mainFOOTER #mainNAV ul li.btn {
    position:absolute;top:0;
    width:25%;height:100px;
    background: rgb(67,67,67);
    background: -moz-linear-gradient(top,  rgba(67,67,67,1) 0%, rgba(35,35,35,1) 40%, rgba(0,0,0,1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(67,67,67,1)), color-stop(40%,rgba(35,35,35,1)), color-stop(100%,rgba(0,0,0,1)));
    background: -webkit-linear-gradient(top,  rgba(67,67,67,1) 0%,rgba(35,35,35,1) 40%,rgba(0,0,0,1) 100%);
    background: -o-linear-gradient(top,  rgba(67,67,67,1) 0%,rgba(35,35,35,1) 40%,rgba(0,0,0,1) 100%);
    background: -ms-linear-gradient(top,  rgba(67,67,67,1) 0%,rgba(35,35,35,1) 40%,rgba(0,0,0,1) 100%);
    background: linear-gradient(to bottom,  rgba(67,67,67,1) 0%,rgba(35,35,35,1) 40%,rgba(0,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#434343', endColorstr='#000000',GradientType=0 );
    transition: background 2s;
    -moz-transition: background 2s; 
    -webkit-transition: background 2s; 
    -o-transition: background 2s;      
}
#mainFOOTER #mainNAV ul li.btn:hover, #mainFOOTER #mainNAV ul li.btn.active {
    background: rgb(168,168,168);
    background: -moz-linear-gradient(top,  rgba(168,168,168,1) 0%, rgba(108,108,108,1) 40%, rgba(0,0,0,1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(168,168,168,1)), color-stop(40%,rgba(108,108,108,1)), color-stop(100%,rgba(0,0,0,1)));
    background: -webkit-linear-gradient(top,  rgba(168,168,168,1) 0%,rgba(108,108,108,1) 40%,rgba(0,0,0,1) 100%);
    background: -o-linear-gradient(top,  rgba(168,168,168,1) 0%,rgba(108,108,108,1) 40%,rgba(0,0,0,1) 100%);
    background: -ms-linear-gradient(top,  rgba(168,168,168,1) 0%,rgba(108,108,108,1) 40%,rgba(0,0,0,1) 100%);
    background: linear-gradient(to bottom,  rgba(168,168,168,1) 0%,rgba(108,108,108,1) 40%,rgba(0,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a8a8a8', endColorstr='#000000',GradientType=0 );
}
#mainFOOTER #mainNAV ul li#btnGAMES {
    left:0%;
}
#mainFOOTER #mainNAV ul li#btnHITS {
    left:25%;
}
#mainFOOTER #mainNAV ul li#btnTOURNOIS {
    left:50%;
}
#mainFOOTER #mainNAV ul li#btnCOMPTE {
    left:75%;
} 
#mainFOOTER #mainNAV ul li.btn a {
    width:100%;height:100px;
    background-image: url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/sprite_mainNAV-XL_new.png');
    background-repeat: no-repeat;
    display:block;
}
    #mainFOOTER #mainNAV ul li#btnGAMES a {
        background-position:  50% 0;
    }
    #mainFOOTER #mainNAV ul li#btnHITS a {
        background-position:  50% 39%;
    }
    #mainFOOTER #mainNAV ul li#btnTOURNOIS a {
        background-position:  50% 67%;
    }
    #mainFOOTER #mainNAV ul li#btnCOMPTE a {
        background-position:  50% 101%;
    }    
#mainFOOTER #mainNAV ul li.btn a:hover {}
#mainFOOTER #mainNAV ul li.btn h2 {
    height:26px;    
    padding-top:73px;
    font-size:1.4em;text-shadow: 0px 0px 2px rgba(0,0,0,0.6);filter: dropshadow(color=#000000, offx=0, offy=0);
}
/* FIN MAIN FOOTER */

    
/* PAGES */
    /* HOME */
        /* HOME : SECTION INDEXGAMES */
        #indexGAMES {}
        #indexGAMES .mid {}
        #indexGAMES .mid .content {max-width:800px;}
        /* Liste */
        #indexGAMES ul.listGames {}
        #indexGAMES ul.listGames li.game {
            position:relative;top:0;left:0;

            height:100px;                
            margin:0 0 12px 0;
            padding:8px;
			padding-left:18px;
			padding-right:18px;
            text-align:left;
            overflow:hidden;
        }
        #indexGAMES ul.listGames li.video {
            height:190px;
			margin:0px;
			padding:0px;
			border-bottom: transparent 1px solid;
			text-align:center;
		}
		
        #indexGAMES ul.listGames li.game:hover {
            background-color:rgba(0,0,0,0.1);
        }
		.landingSubTitleADW_9{
		
		}
		.buttonLPAccount{
			-webkit-border-radius:4px; 
            -moz-border-radius:4px; 
            border-radius:4px;
            padding:0 15px;
			padding-top:3px;
			padding-bottom:3px;
			font-size:1.4em;

		}
        #indexGAMES ul.listGames li.game a.gamePlay {
			font-family:robotoregular;
			position:absolute;right:10px;
            min-width:60px;height: 30px;
            -webkit-border-radius:4px; 
            -moz-border-radius:4px; 
            border-radius:4px;
            padding:0 15px;
			padding-top:3px;
			padding-bottom:3px;
            font-size:1.2em;
			line-height:30px;text-align:center;
            color:#ffffff;    
            z-index:101;
			margin-right:2%;
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#443f3f', endColorstr='#0a0809',GradientType=0 );
        }         
   
        #indexGAMES ul.listGames li.game a.gameDetail {
            position:absolute;top:0;left:0;z-index:100;
            width:100%;height:100%;
        } 
        #indexGAMES ul.listGames li.game a.left_video {
            position:absolute;top:0;left:0;z-index:100;
            width:50%;height:100%;
        }
		.fit_left_video {
            position:absolute;top:0;left:0;z-index:100;
            width:50%;height:100%;
        }
		.spacervid{
			height:150px;
		}
		.smallspacervid{
			height:15px;
		}
        #indexGAMES ul.listGames li.game a.stream_video {
            z-index:100;
			width: 22%;
			height: 100%;
			display: inline-table;
			margin-left: 2px;
			margin-right: 2px;
        }		 
		.2stream_video {
			z-index:100;
			width: 22%;
			height: 100%;
			display: inline-table;
			margin-left: 2px;
			margin-right: 2px;
		}
        #indexGAMES ul.listGames li.game a.right_video {
            position:absolute;top:0;right:0;z-index:100;
            width:50%;height:100%;
        }
        .fit_right_video {
            position:absolute;top:0;right:0;z-index:100;
            width:50%;height:100%;
        }				
        #indexGAMES ul.listGames li.game .labs {
            position:absolute;top:8px;right:0;
            padding:0px 10px; 
            font-size:1.2em;
        }
        #indexGAMES ul.listGames li.game .btnPICTOS {
            width:25%;max-width:100px;
        }        
        #indexGAMES ul.listGames li.game .btnPICTOS img {
            width:100%;max-width:100px;
            display:inherit;
			-webkit-border-radius:15px; 
			-moz-border-radius:15px; 
			border-radius:15px;
        }
        #indexGAMES ul.listGames li.game .editoGAME {
            position:absolute;top:8px;left:116px;
            max-width:500px;
			margin-left:4%;
        }   
        #indexGAMES ul.listGames li.game .editoGAME h3 {
            font-size:1.8em;
			font-family:robotoregular;
			
            margin:0 0 5px 0;
			text-transform:capitalize;
        }      
        #indexGAMES ul.listGames li.game .editoGAME p {
			font-family:robotolight;
			font-weight:bolder;
        }
        #indexGAMES ul.listGames li.game .editoGAME p a {
            background:#000000;
            padding:3px 8px;
            -webkit-border-radius:4px; 
            -moz-border-radius:4px; 
            border-radius:4px;              
            display:inline-block;
        } 
		.bottomdiv_at{
			background-color:#c681b8;
		}
		.bottomdiv2_at{
			background-color:#7dc09b;
		}		
		.topdiv_at{
			background-color:#3f8cba;
		} 
        .top_pic_at{
			width:100%;
		}
        .landingSubTitleADW_5{
			font-size: 20px;
			color:white;
			letter-spacing:1px;
		}
		.bottom_button_at{
			font-size: 13px;
			letter-spacing: 1px;
			font-weight: bold;
			background: #edb761;
			padding-top: 2px;
			padding-bottom: 2px;
			padding-left: 20px;
			padding-right: 20px;
		}
		.bottom_button2_at{
			font-size: 13px;
			letter-spacing: 1px;
			font-weight: bold;
			background: transparent;
			padding-top: 10px;
			padding-bottom: 10px;
			padding-left: 20px;
			padding-right: 20px;
			border: solid 1px white;
            -webkit-border-radius:3px; 
            -moz-border-radius:3px; 
            border-radius:3px;			
		}		
		.landingSubTitleADW_6{
			font-size: 15px;
			color: black;
			font-weight: bold;
		}
		.landingSubTitleADW_7{
			font-size: 10px;
			color: black;
			font-weight: bold;
		}
		.landingSubTitleADW_8{
			font-size: 10px;
			color: black;
			font-weight: bold;
		}			
    /* GAMES : DETAILS */
        .detailSECTION #headerGAME {
           width:89%;height:100px;          
		   padding-bottom:5px;
		   padding-top:8px;
		   
        }   
        #navSECTIONGAME .nav-tabs>li,.nav-pills>li{
            width:27%;
            margin:0 3%;
            text-align:center;
        }
        #tabGAME {}
        #tabGAME a {
            height: 30px;
            -webkit-border-radius:5px; 
            -moz-border-radius:5px; 
            border-radius:5px;
            padding:0 8px;
            font-size:1.2em;line-height:30px;
            color:#ffffff;    
            background: rgb(68,63,63);
            background: -moz-linear-gradient(top,  rgba(68,63,63,1) 0%, rgba(68,63,63,1) 50%, rgba(10,14,10,1) 51%, rgba(10,8,9,1) 100%);
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(68,63,63,1)), color-stop(50%,rgba(68,63,63,1)), color-stop(51%,rgba(10,14,10,1)), color-stop(100%,rgba(10,8,9,1)));
            background: -webkit-linear-gradient(top,  rgba(68,63,63,1) 0%,rgba(68,63,63,1) 50%,rgba(10,14,10,1) 51%,rgba(10,8,9,1) 100%);
            background: -o-linear-gradient(top,  rgba(68,63,63,1) 0%,rgba(68,63,63,1) 50%,rgba(10,14,10,1) 51%,rgba(10,8,9,1) 100%);
            background: -ms-linear-gradient(top,  rgba(68,63,63,1) 0%,rgba(68,63,63,1) 50%,rgba(10,14,10,1) 51%,rgba(10,8,9,1) 100%);
            background: linear-gradient(to bottom,  rgba(68,63,63,1) 0%,rgba(68,63,63,1) 50%,rgba(10,14,10,1) 51%,rgba(10,8,9,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#443f3f', endColorstr='#0a0809',GradientType=0 );
        }
        #tabGAME li.active a {
            background:#ffffff;
            color:#000000;
        }
        #tabGAME li a:hover {
            background:#ffffff;
            color:#000000;
        }        
        
        
    
    /* HITS */
        /* HITS : SECTION MOREGAMES */
        #moreGAMES {}
        #moreGAMES .mid {}
        #moreGAMES .mid .content {
            width:94%;max-width:800px;
            padding:8px;
            -webkit-border-radius:10px; 
            -moz-border-radius:10px; 
            border-radius:10px;                      
            overflow:hidden;
        }
        /* Liste */
        #moreGAMES ul.listGames {}
        #moreGAMES ul.listGames li.game {
            -webkit-border-radius:44px; 
            -moz-border-radius:44px; 
            border-radius:44px;                
            margin:0 0 18px 0;
            overflow:hidden;
        }
        #moreGAMES ul.listGames li.game.border {
            border:#2f7a47 4px solid;
        }        
        #moreGAMES ul.listGames li.game img {
            width:100%;max-width:800px;
            display:block;
        
        }

        
    /* TOURNOIS */
        /* TOURNOIS : SECTION MORETOURNOIS */
        .detailSECTION #headerTOURNOIS {
           width:100%;height:180px;             
        }   
        .detailSECTION #headerTOURNOIS .date {
            position:absolute;top:110px;right:8px;
            font-size:1.2em;line-height:1.8em;text-align:right;
        }
        .detailSECTION #headerTOURNOIS .status {
            position:absolute;top:110px;left:8px;
            font-size:1.2em;line-height:1.8em;text-align:left;
        }
        .detailSECTION #prizeGAME {
            text-align:left;
        }
        .detailSECTION .firstPRIZE {
            margin: 0 0 8px 0;
            padding:0 0 0 8px;
            font-size:1.4em;line-height:1.8em;
        }
        #navSECTIONTOURNOIS .nav-tabs>li,.nav-pills>li {
            width:27%;
            margin:0 11%;
            text-align:center;
        }
    /* COMPTE */
        /* COMPTE : STRUCTURE */
		.width27{
			width:27%;
		}
		.radius5{
			-webkit-border-radius:5px; 
            -moz-border-radius:5px; 
            border-radius:5px;       
		}
		.radius2{
			-webkit-border-radius:2px; 
            -moz-border-radius:2px; 
            border-radius:2px;       
		}		
		.radius10{
			-webkit-border-radius:10px; 
            -moz-border-radius:10px; 
            border-radius:10px;       
		}
		.account_title_nc{
			padding-top: 15px;
			padding-bottom: 15px;		
		}
		.account_title_nc_1{
			font-size: 2.18em;
			font-weight: bold;
			letter-spacing: 0.5px;
			line-height: 1;
		}
		.account_title_nc_2{
			font-family: robotoregular;      
			font-size: 3em;    
			font-weight: bold;      
			letter-spacing: 0.5px;
			line-height: 1.1;
		}
		.account_title_nc_3{
			font-size: 1.65em;
			font-family: robotoregular;
			line-height: 1.3;

		}
		.account_title_nc_4{
		    font-family: robotoregular;
			letter-spacing: .5px;
			font-size: 1.49em;
			padding-top: 10px;
			padding-bottom: 20px;
			font-weight: bold;
			color: #C0BBBB;
		}
		.account_title_nc_5{
			color: black;
			font-family: robotoregular;
			font-size: 1.8em;
			background: white;
			font-weight: bold;
			padding-top: 2px;
			padding-bottom: 2px;
			padding-left: 9px;
			padding-right: 9px;		
		}.account_title_nc_6{
			padding-top: 5px;
			padding-bottom: 15px;
			font-family: robotoregular;
			font-size: 1.15em;
			font-weight: bold;
			letter-spacing: .4px;		
		}
		.account_title_nc_1_MCI {
			font-size: 2.4em;
			font-weight: bold;
			letter-spacing: 20px;
			line-height: 1;
			margin-left: 11px;
		}
		.account_title_nc_2_MCI {
			font-family: robotoregular;
			font-size: 4.1em;
			font-weight: bold;
			letter-spacing: 28px;
			line-height: 1.1;
			margin-left: 23px;
			padding-bottom: 14px;
		}
		.account_title_nc_3_MCI {
			font-size: 1.54em;
			font-family: robotoregular;
			line-height: 1;
			letter-spacing: 0;
			padding-bottom: 6px;
			margin-right: 1px;
		}
		.account_title_nc_4_MCI {
			font-family: robotoregular;
			letter-spacing: 2.2px;
			font-size: 1.8em;
			padding-bottom: 25px;
			color: white;
			line-height: .5;
		}
		.account_title_nc_5_MCI {
			color: black;
			font-family: robotoregular;
			font-size: 2em;
			background: white;
			font-weight: bold;
			letter-spacing: 7px;
			padding-left: 10px;
			padding-right: 10px;
		}	
		.account_title_nc_6_MCI{
			padding-top: 5px;
			padding-bottom: 15px;
			font-family: robotoregular;
			font-size: 1.15em;
			font-weight: bold;
			letter-spacing: .4px;		
		}		
		.account_title_nc_promoused_1 {
			letter-spacing: .5px;
			font-family: robotoregular;
			font-size: 2.9em;
			font-weight: bold;
		}
		.account_title_nc_promoused_2 {
			letter-spacing: .5px;
			font-family: robotoregular;
			font-size: 3.4em;
			font-weight: bold;			
		}
		.account_title_nc_promoused_3 {
			letter-spacing: .5px;
			font-family: robotoregular;
			font-weight: bold;
			font-size: 2.2em;
			padding-bottom: 4px;

			color: #C0BBBB;
		}		
		.account_title_c{
			font-family: robotoregular;

			padding-top:10px;
		}
		.account_title_c_1{
			font-family: robotoregular;
			font-size: 3.1em;
			font-weight: bold;
		}
		.account_title_c_2{
		    font-family: robotoregular;
			font-size: 4em;
			font-weight: bold;
		}
		.account_title_c_3{
			font-family: robotoregular;
			font-size: 1.3em;
			padding-top: 15px;
			padding-bottom: 15px;
			line-height: 1.3;
		}
		.account_title_c_4{
			font-family: robotoregular;
			font-size: 1.5em;
			letter-spacing:.4px;
		}
		.account_title_c_4pt{
			font-family: robotoregular;
			font-size: 1.6em;
			letter-spacing:.4px;
		}		
		.account_title_c_5{
			font-size:1em;
			font-family: robotoregular;
		}
		.account_title_c_6{
			font-family: robotoregular;
			font-size:1em;
			letter-spacing:1.6px;
		}
		.account_title_c_1_MCI {
			font-family: robotoregular;
			font-size: 4.1em;
			font-weight: bold;
			padding-bottom: 4px;
			letter-spacing: 2px;
		}
		.account_title_c_2_MCI {
			font-family: robotoregular;
			font-size: 2.65em;
			font-weight: bold;
		}
		.account_title_c_3_MCI {
			font-family: robotoregular;
			font-size: 1.3em;
			padding-top: 16px;
			padding-bottom: 15px;
			line-height: 1.3;
			letter-spacing: .5px;
		}
		.account_title_c_4_MCI{
			font-family: robotoregular;
			font-size: 1.3em;
			letter-spacing:1.4px;
		}
		.account_title_c_5_MCI {
			font-size: 1em;
			letter-spacing: 1.8px;
			font-family: robotoregular;
		}
		.account_title_c_6_MCI {
			font-family: robotoregular;
			font-size: 1em;
			letter-spacing: .9px;
		}		
		.weekmonth_1{
		    font-size: 2em;
		    font-family: robotoregular;

		}
		.weekmonth_2{
		    font-size: 1.5em;
		    font-family: robotoregular;

		}
		.paddingTerms{
			padding-top:15px;
			padding-left:5px;
			padding-right:5px;
		}
        #detailCOMPTE {}
        #detailCOMPTE .mid {}
        #detailCOMPTE .mid .content {
            max-width:1000px;        
        }
        #detailCOMPTE .wrapCONTENT  {width:100%;margin:0 auto;}

        #navCAT.sectionCOMPTE ul {
            margin: 13px auto;
        }        
        #navCAT.sectionCOMPTE ul li.btn {
 
        }
        #navCAT.sectionCOMPTE .nav-tabs > li > a {
            -webkit-border-radius:none; 
            -moz-border-radius:none; 
            border-radius:none;  
            background:none;
        }   
        #detailCOMPTE {}
        #detailCOMPTE .bloc {
            width:60%;
            margin: 0 auto 16px auto;
        
        }
        #detailCOMPTE .bloc a.picto {
            height:200x;
            margin:0 22px 0 0;
            float:left;
            overflow:hidden;
        }    
        #detailCOMPTE .bloc a.picto img {
            max-width:200px;
            max-height:64px;
            display:block;
            margin: auto;
        }                 
        #detailCOMPTE .bloc p {
            font-size:1.9em;line-height:64px;
            float:left;
        }
        #detailCOMPTE .spacerMID {
            position:relative;top:0;
            width:60%;height:14px;
            margin:30px auto;
        } 
        #detailCOMPTE .spacerMID .separateur {
            position:absolute;top:0;
            width:40%;height:4px;
            margin:0;
        }           
        #detailCOMPTE .spacerMID .separateurL {
            left:0;
        }
        #detailCOMPTE .spacerMID .separateurR {
            right:0;
        }         
        #detailCOMPTE .spacerMID .OR {
            padding:0 0px 0 0px;
            position:inherit;top:-10px;left:0;
            width:100%;
            font-size:1.8em;
        }        
        #detailCOMPTE .payment {
            position:absolute;top:-10px;left:0;
            width:100%;
            font-size:0.8em;
            font-style: italic;
            text-align:center;
        }               
        #detailCOMPTE .payment .IMG {
            margin: 8px auto;
            width:80%;
            max-width: 251px;
            max-height: 70px;
            display: block;
        }        
        
        
        /* COMPTE : SECTION STATUS */
		h2.colorPARTNER{
			font-size: 1.1em;
			margin: 0.83em 0;
		}
       
		h4.colorPARTNER {
            width:100%;
            margin-right: auto;
			margin-bottom: 12px;
			margin-left: auto;
            font-size:1.2em;text-align:center;font-weight:bold;
        } 
		div.cgvBouygues{
			text-align:left;
		}
		p.cgvBouygues{
			text-align:left;
		}
		
		#CGV{
			max-width:800px;
			margin-left: auto;
			margin-right: auto;
			text-align:justify;
		}
		
		.statut {
            
        }
        .statut p {
            width: 70%;
            margin:10px auto;
            padding:8px 6px 12px 10px;       
            font-size:2.5em;line-height:1.6em;text-align:left;

        }
        .btnWRAP {
            width:240px;
            padding:10px;
            margin:20px auto;
            -webkit-border-radius:10px; 
            -moz-border-radius:10px; 
            border-radius:10px;             
        }
        .prize {
            font-size:1.8em;
            text-align:center;
        }
        .acceptOFFER {
            margin:12px auto 0 auto;
        }                          
                          
        /* COMPTE : SECTION PARTAGER */
        #shareCOMPTE {}
        #shareCOMPTE .bloc {
            width:60%;
            margin: 0 auto 16px auto;
        
        }
        #shareCOMPTE .bloc a.picto {
            width:90px;height:90px;
            margin:0 22px 0 0;
            float:left;
        }    
        #shareCOMPTE .bloc a.picto img {
            width:100%;
            display:block;
            margin: auto;
        }                 
        #shareCOMPTE .bloc p {
            font-size:1.9em;line-height:90px;
            float:left;
        }
        #shareCOMPTE .spacerMID {
            position:relative;top:0;
            width:60%;height:14px;
            margin:30px auto;
        } 
        #shareCOMPTE .spacerMID .separateur {
            position:absolute;top:0;
            width:40%;height:4px;
            margin:0;
        }           
        #shareCOMPTE .spacerMID .separateurL {
            left:0;
        }
        #shareCOMPTE .spacerMID .separateurR {
            right:0;
        }         
        #shareCOMPTE .spacerMID .OR {
            position:absolute;top:-10px;left:0;
            width:100%;
            font-size:1.8em;
        }        

        
        #shareCOMPTE #boxFB {}
        #shareCOMPTE #boxTWITTER {}
         
                
        

/*////////////////////////////////////////////////////////////////////////////
MISE EN PAGE DES PLUGINS /////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////*/
	#seeMore, #seeLess{
		*color:#ff7c67;
		*background:#391a39;
	}
	.show_more{
		min-width:15%;
		max-width:15%;
	}
	#gamelist0{
		overflow-y:scroll;
	}
	#gamelist2{
		overflow-y:scroll;
	}	
	#gamelist3{
		overflow-y:scroll;
	}
	#gamelist{
		overflow-y:scroll;
		overflow:hidden;
	}
	.fixed{
		position:fixed;
		-webkit-backface-visibility: hidden;
		top:0px;	
		bottom:0px;
		
	}
	.relative{
		position:relative;
	
	}
	.minilogos{
		width:9%;
		margin-left:8px;

	}
	.centredtitle{
		display: -webkit-box;
		display: -webkit-flex;
		display: -moz-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;
	}
	.scrollmenu{
		background-color:rgba(33, 39, 33, 0.7);
		font-family:robotolight;
		overflow: hidden;
		overflow-y: scroll;
	}
	.scrollactive{
	  color:#ff7c67;
	 
	}
	.scrollsubactive{
	  background: url(https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/BT_scrollmenu_selector.png) no-repeat left;
	  background-size: 4px 6px;
	}
	.scroll_game_not_active{
		color:#92493e;
	}
	.scroll_game_active{
		color:#ff7c67;

	}
	.scrollmenulist{
		text-align:left;
		color:#ff7c67;
	}
	.categorymenu{
		line-height:0.8;
		padding-left: 22px;

	}
	.detail_subtitle{
		padding-left:18px;
		padding-right:18px;
		font-weight:400;
		padding-top:15px;
		padding-bottom:15px;
		color:#ffffff;
		font-size:1.2em;
		letter-spacing:0.3px;
		text-align:left;
		font-family:robotomedium;
		
	}
	.scroll_img_logo{
		width:26px;text-align:left;
	}
	.panel{
		background:rgba(33, 39, 33, 0.7);
		position: fixed;
		left: -11em; /*left or right and the width of your navigation panel*/
		width: 11em; /*should match the above value*/
		backface-visibility: hidden;
	}
	.welcome_okbutton{
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;
		font-size: 1.1em;
		line-height: 35px;
		font-family: robotoregular;
		background:#683653;
		color:#ff7c67;
	}
	.robotoregular{
		font-family: robotoregular;
		letter-spacing:.5px;
	}
	.panel ul li{

	}
	.panel ul li a{
		font-size: 1.25em;
		text-decoration:none;
		text-transform:uppercase;
		letter-spacing: 0.4px;
        vertical-align: super;
		padding-left: 5px;
		padding-top: 1em;
		padding-bottom: 1em;
		box-sizing: border-box;
		width: 77%;		
		display:inline-block;
	}
	.panel ul li a:active {
	}

    /* PLUGINS JS SLIDER */    
    #slides {display: none;}

    .slidesjs-navigation {
        position:absolute;top:35%;z-index:9999;
        width:26px;height:24px;
        background:url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/nav_sliderLR.png') no-repeat 0 0;
    }
    .slidesjs-slide {overflow:visible!important;}
    .slidesjs-previous {
        left:-18px;
    }
    .slidesjs-next {
        right:-18px;
        background-position: -26px 0;
    }
    .slidesjs-container {
		padding-bottom:50px;
	}

    .container {
        position:relative;top:0;left:0;
        margin: 0 auto;
    }

    
    .slidesjs-pagination  {
        margin:14px auto 0 auto;
        text-align:center;
    } 
    .slidesjs-pagination .slidesjs-pagination-item {
        display: inline-block;
        position: relative;
        width: 12px;
        height: 12px;
        margin: 4px;
        overflow:hidden;
        background:url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/nav_slider.png') no-repeat 0 0; 
    }
    .slidesjs-pagination .slidesjs-pagination-item.active {
        background-position:0 -30px;
    }    
     .slidesjs-pagination .slidesjs-pagination-item:hover {
        background-position:0 -60px;
    }    
      
    
    .slidesjs-pagination a {
        width:100%;
        height:100%;
        display:block;
        padding:30px 0 0 0;

    }
    .slidesjs-pagination a:hover {
    }
    
    .slidesjs-pagination a.active {
    }
	.specialtxt{
		border-radius:0px;
	}


	/* Media quires for a responsive layout */

    /* For tablets & smart phones */
    @media (max-width: 767px) {
		#wrapSITE .account {
		top:0px;
		}
      .container {
        width: auto
      }
	  .testozz{
		width:100%;
		max-width:500px;
		margin-top:0px;
		}
    }

    /* For smartphones */
    @media (max-width: 480px) {
      .container {
        width: auto
      }
    }

    /* For smaller displays like laptops */
    @media (min-width: 768px) and (max-width: 979px) {
      .container {
        width: 600px
      }
    }

    /* For larger displays */
    @media (min-width: 1200px) {
      .container {
        width: 600px
      }
    } 
        
    

/* RESPONSIVE */


        @media only screen and (orientation:landscape) {
            .layerLANDSCAPE {
                position:fixed;top:0;left:0;z-index:9999;
                width:100%;height:100%;
                background:#000000 url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/noLANDSCAPE.png') no-repeat 50% 50%;
                background-size:contain;
                opacity:1;
                display:block;
            }
            .layerLANDSCAPE_BT {
                position:fixed;top:0;left:0;z-index:9999;
                width:100%;height:100%;
                background:#391a39 url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/BT_nolandscape.png') no-repeat 50% 50%;
                background-size:contain;
                opacity:1;
                display:block;
            }			
            .layerLANDSCAPE_Firefox {
                position:fixed;top:0;left:0;z-index:9999;
                width:100%;height:100%;
                background:#0d3a4b url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/noLANDSCAPE_Firefox.png') no-repeat 50% 50%;
                background-size:contain;
                opacity:1;
                display:block;
            }			
			.layerLANDSCAPE2 {
				position:fixed;top:0;left:0;z-index:8999;
				width:100%;height:100%;
				display:none;
				opacity:0;
				-webkit-transition: all 0.8s ease-in-out;
				-moz-transition: all 0.8s ease-in-out;
				-o-transition: all 0.8s ease-in-out;
				transition: all 0.8s ease-in-out;           
			}	
			.layerLANDSCAPE_BT2 {
                position:fixed;top:0;left:0;z-index:9999;
                width:100%;height:100%;
                background:#391a39 url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/BT_nolandscape.png') no-repeat 50% 50%;
                background-size:contain;
                opacity:0;
                display:none;
            }			
        }

        /* All */
        @media only screen and (max-width:1920px) {
            .content {width:1440px;}

			#mainHEADER .mid .content {
				padding-left: 18px;
				max-width: 1440px;
				position: fixed;
				backface-visibility: hidden;
				z-index: 9999;
				background-color: #683653;
			}
			 .header_right {
				width: 29%;
				text-align: left;
				float: right;
				font-size: 1em;
			}      
			
			#homepage-images .flex-caption {
				font-family: robotoregular;
				position: absolute;
				bottom: 0;
				left: 0;
				margin-bottom: 25px;
				margin-left: 25px;
				padding-top: 5px;
				padding-bottom: 5px;
				padding-left: 10px;
				padding-right: 10px;
				color: white;
				-webkit-border-radius: 3px;
				-moz-border-radius: 3px;
				border-radius: 5px;
				background-color: #ff7c67;
				font-size: 1.3em;
				letter-spacing: 1px;
			}	
			#homepage-images .flex-caption-sub {
				font-family: robotolight;
				position: absolute;
				bottom: 0;
				left: 0;
				margin-bottom: 8px;
				font-size: 1.3em;
				letter-spacing: 1px;
				margin-left: 26px;
				color: #9c9c9c;
				font-weight: bolder;
			}	
			.landingMainPicture{
				width:30%;	
			}			
			.landingForm{
				width: 40%;
				margin: 0;
				padding-bottom: 0;
				margin: auto;			
				margin-bottom: 10px;
				margin-top: 6px;
				position: relative;
			}
			.landingBottom_2 {
				width: 30%;
				margin-left: auto;
				margin-right: auto;
				padding-top: 1%;
				padding-bottom: 6%;
			}
			.noradiusbutton{
				border-radius: 0;
				width:80%;
			}
			.landing3gOKbutton_2 {
				height: 47px;
				border-radius: 7px;
				width: 73%;
				margin: 0;
				background: #00ff36;
				color: black;
				font-weight: bold;
				padding: 0;
				margin-top: 5px;
			}
			.landing3gOKbutton_4 {
				height: 47px;
				border-radius: 7px;
				width: 70%;
				margin: 0;
				background: #683653;
				color: black;
				font-weight: bold;
				padding: 0;
				margin-top: 5px;
			}

			.landing3gOKbutton_3 {
				height:100%;
				border-radius: 7px;
				width:80%;
				margin: 0;
				background: transparent;
				color: black;
				font-weight: bold;
				padding: 0;
				margin-top: 5px;
			}
			.landing3gOKbutton_5 {
				height:100%;
				border-radius: 7px;
				width:70%;
				margin: 0;
				background: transparent;
				color: black;
				font-weight: bold;
				padding: 0;
			}			
			.okbuttonXXX{
				width:71%;
			}			
			.picZALP{
				width:60%;

			}
			.landingLeftArrow {
				width: 11%;
				float: left;
			}			

        }              
        @media only screen and (max-width:3920px) {
            .content {width:1440px;}

			#mainHEADER .mid .content {
				padding-left: 18px;
				max-width: 1440px;
				position: fixed;
				backface-visibility: hidden;
				z-index: 9999;
				background-color: #683653;
			}
			 .header_right {
				width: 29%;
				text-align: left;
				float: right;
				font-size: 1em;
			}      
			
			#homepage-images .flex-caption {
				font-family: robotoregular;
				position: absolute;
				bottom: 0;
				left: 0;
				margin-bottom: 25px;
				margin-left: 25px;
				padding-top: 5px;
				padding-bottom: 5px;
				padding-left: 10px;
				padding-right: 10px;
				color: white;
				-webkit-border-radius: 3px;
				-moz-border-radius: 3px;
				border-radius: 5px;
				background-color: #ff7c67;
				font-size: 1.3em;
				letter-spacing: 1px;
			}	
			#homepage-images .flex-caption-sub {
				font-family: robotolight;
				position: absolute;
				bottom: 0;
				left: 0;
				margin-bottom: 8px;
				font-size: 1.3em;
				letter-spacing: 1px;
				margin-left: 26px;
				color: #9c9c9c;
				font-weight: bolder;
			}	
			.landingMainPicture{
				width:30%;	
			}			
			.landingForm{
				width: 40%;
				padding-bottom: 0;
				margin: auto;			

				margin-bottom: 10px;
				margin-top: 9px;
				position: relative;
			}
			.landingBottom_2 {
				width: 30%;
				margin-left: auto;
				margin-right: auto;
				padding-top: 1%;
				padding-bottom: 6%;
			}
			.noradiusbutton{
				border-radius: 0;
				width:80%;
			}
			.landing3gOKbutton_2 {
				height: 47px;
				border-radius: 7px;
				width: 73%;
				margin: 0;
				background: #00ff36;
				color: black;
				font-weight: bold;
				padding: 0;
				margin-top: 5px;
			}
			.landing3gOKbutton_4 {
				height: 47px;
				border-radius: 7px;
				width: 70%;
				margin: 0;
				background: #683653;
				color: black;
				font-weight: bold;
				padding: 0;
				margin-top: 5px;
			}
			
			.landing3gOKbutton_3 {
				height:100%;
				border-radius: 0px;
				width:80%;
				margin: 0;
				background:transparent;
				color: black;
				font-weight: bold;
				padding: 0;
				margin-top: 5px;
			}
			.landing3gOKbutton_5 {
				height:100%;
				border-radius: 0px;
				width:70%;
				margin: 0;
				background:transparent;
				color: black;
				font-weight: bold;
				padding: 0;
			}			
			.okbuttonXXX{
				width:71%;
			}
			.picZALP{
				width:60%;

			}			
			.landingLeftArrow {
				width: 11%;
				float: left;
			}			

        }             
        @media only screen and (max-width:1600px) {
            .content {width:1280px;} 
		
        }          
          
        
        @media only screen and (max-width:1280px) {
            .content {width:1140px;}
            .header_right{
				width:88%;
				text-align:left;
				
				float:right;
				font-size:1em;  
			}	
        }            
        
        @media only screen and (max-width:1024px) {
            .content {width:960px;}
			.ci_footer{
				left:0%;
				right:0%;
				padding-top:17%;
			}
        }               
        
        @media only screen and (min-width: 961px) {
            #maskLIST {
                display:none;
            }
			.mystreaming6_span_title2{
				font-size:0.5vw;
			}
			.mystreaming6_span_title{
				font-size:1vw;
			}
			.mystreaming6_playerRight_text1{
				font-size:1.1vw;
			}
			.mystreaming6_playerRight_text2{
				font-size: 0.8vw;
			}
			.mystreaming6_playerRight_text3{
				font-size: 0.6vw;
			}			
        }
        
        @media only screen and (max-width: 959px) {
            .mid {width:100%;
				margin-bottom:70px;
			}
			.popipo{
				margin-bottom:55px;
			}
			
            .content {width: 100%;}
			
        }
        
        @media only screen and (max-width: 800px) {
            #mainHeader .mid .content {
				padding:8px;
				padding-left:18px;
				height:8.5%;
                width:100%;
				position:fixed;
				z-index:9999;
				background-color:#683653;
				backface-visibility: hidden;
            }           
			#wrapSITE .account {
				top:0px;
			}
			#mainLOGO img.logo {
				width:71%;
			}
			#mainLOGO img.logo2 {
				width:40%;
			}
			.scroll_img_logo{
				width:47px;text-align:left;
			}
			#homepage-images .flex-caption {
				font-family: robotoregular;
				position: absolute;
				bottom: 0;
				left: 0;
				margin-bottom: 50px;
				margin-left: 50px;
				padding-top: 10px;
				padding-bottom: 10px;
				padding-left: 20px;
				padding-right: 20px;
				color: white;
				-webkit-border-radius: 3px;
				-moz-border-radius: 3px;
				border-radius: 5px;
				background-color: #ff7c67;
				font-size: 2.3em;
				letter-spacing: 2px;
			}	
			#homepage-images .flex-caption-sub {
				font-family: robotolight;
				position: absolute;
				bottom: 0;
				left: 0;
				margin-bottom: 16px;
				font-size: 2.3em;
				letter-spacing: 1.5px;
				margin-left: 52px;
				color: #9c9c9c;
				font-weight: bolder;
			}
			#indexGAMES ul.listGames li.game .editoGAME h3{
			    font-size: 2.4em;
				font-family: robotoregular;
				margin: 0 0 8px 0;
				text-transform: capitalize;
			}
			#indexGAMES ul.listGames li.game .editoGAME p{
				font-family: robotolight;
				font-weight: bolder;	
				font-size: 2.1em;
			}
			.buttonLPAccount{
				-webkit-border-radius: 4px;
				-moz-border-radius: 4px;
				border-radius: 4px;
				padding: 0 25px;
				padding-top: 8px;
				padding-bottom: 5px;
				font-size: 3em;
			}			
			#indexGAMES ul.listGames li.game a.gamePlay {
				font-family: robotoregular;
				position: absolute;
				right: 10px;
				min-width: 73px;
				height: 41px;
				-webkit-border-radius: 4px;
				-moz-border-radius: 4px;
				border-radius: 4px;
				padding: 0 25px;
				padding-top: 8px;
				padding-bottom: 5px;
				font-size: 2.3em;
				line-height: 30px;
				text-align: center;
				color: #fff;
				z-index: 101;
				margin-right: 2%;
            } 
			.scrollmenu_spacer{
				display: block;
				height: 1px;
				border: 0;
				border-bottom: #444545 1px solid;
			    padding: 0;
			}
			.scrollmenu_spacer_MCI{
				display: block;
				height: 1px;
				border: 0;
				border-bottom: #595F66 1px solid;
			    padding: 0;
			}			
			.panel ul li a {
				font-size: 2em;
				text-decoration: none;
				text-transform: uppercase;
				letter-spacing: .4px;
				vertical-align: super;
				padding-left: 5px;
				padding-top: 1em;
				padding-bottom: 1em;
				box-sizing: border-box;
				width: 77%;
				display: inline-block;
			}
			.scrollmenu_subtitle {
				font-family: robotolight;
				color: #606566;
				padding-bottom: 30px;
				font-size: 2em;
			}
			.account_title_nc {
				padding-top: 30px;
				padding-bottom: 30px;
			}		
			.account_title_nc_1 {
				font-size: 4.36em;
				font-weight: bold;
				letter-spacing: .5px;
				line-height: 1;
			}			
			.account_title_nc_2 {
				font-family: robotoregular;
				font-size: 6em;
				font-weight: bold;
				letter-spacing: .5px;
				line-height: 1.1;
			}	
			.account_title_nc_3 {
				font-size: 3.3em;
				font-family: robotoregular;
				line-height: 1.3;
			}			
			.account_title_nc_4 {
				font-family: robotoregular;
				letter-spacing: .5px;
				font-size: 2.98em;
				padding-top: 10px;
				padding-bottom: 20px;
				font-weight: bold;
				color: #c0bbbb;
			}		
			.account_title_nc_5 {
				color: black;
				font-family: robotoregular;
				font-size: 3.6em;
				background: white;
				font-weight: bold;
				padding-top: 3px;
				padding-bottom: 3px;
				padding-left: 10px;
				padding-right: 10px;
			}	
			.account_title_nc_6 {
				padding-top: 5px;
				padding-bottom: 15px;
				font-family: robotoregular;
				font-size: 2.30em;
				font-weight: bold;
				letter-spacing: .4px;
			}
			.account_title_nc_1_MCI {
				font-size: 6em;
				font-weight: bold;
				letter-spacing: 30px;
				line-height: 1;
			}			
			.account_title_nc_2_MCI {
				font-family: robotoregular;
				font-size: 10em;
				font-weight: bold;
				letter-spacing: 27px;
				line-height: 1.2;
			}	
			.account_title_nc_3_MCI {
				font-size: 3.8em;
				font-family: robotoregular;
				line-height: 2.5;

			}			
			.account_title_nc_4_MCI {
				font-family: robotoregular;
				letter-spacing: .5px;
				font-size: 2.7em;
				padding-top: 10px;
				padding-bottom: 50px;
				font-weight: bold;
				color: #c0bbbb;
			}		
			.account_title_nc_5_MCI {
				color: black;
				font-family: robotoregular;
				font-size: 3.6em;
				background: white;
				font-weight: bold;


			}	
			.account_title_nc_6_MCI {
				padding-top: 5px;
				padding-bottom: 15px;
				font-family: robotoregular;
				font-size: 2.30em;
				font-weight: bold;
				letter-spacing: .4px;
			}						
        }
                        
        @media only screen and (max-width: 768px) {
            #indexGAMES ul.listGames li.game .editoGAME {
                max-width:350px;
				margin-left:4%;

            }               
        }            
        
        @media only screen and (max-width: 640px) {
            #indexGAMES ul.listGames li.game .editoGAME {
                max-width:300px;
				margin-left:4%;

            }  
			.ci_footer{
				left:0%;
				right:0%;
				bottom:-25%;
				padding-top:17%;
			}
			.iframeSize{
				height:350px;
				overflow:hidden;
				width:100%;

			}
            #main {
            }    
            /* Nav Cat */
            #navCAT {
               padding:10px 0;
               filter: dropshadow(color=#000000, offx=0, offy=0);
            }
            #navCAT .mid {}
            #navCAT .mid .content {
                max-width:800px;height:38px;
                overflow:hidden;
            }
            #navCAT ul {
                width:auto;height:22px;
                margin:8px 0;
                overflow:hidden;
            }
            #navCAT.sectionCOMPTE ul {
                margin: 8px auto;
            }               
            #navCAT ul li.btn {
                height:22px;
                -webkit-border-radius:5px; 
                -moz-border-radius:5px; 
                border-radius:5px;
                padding:0 8px;
                margin:0 8px;
                float:left;
                font-size:1.2em;line-height:22px;
            }
            /* Mask List */
            #maskLIST {
                bottom:60px;
            }
            .navSECTION {
                bottom:80px;
            }            
            /* Main Footer */
            #mainFOOTER {
                height:80px;
            }
            #mainFOOTER #mainNAV ul {
                height:80px;
            }
            #mainFOOTER #mainNAV ul li.btn {
                height:80px;    
            }
            #mainFOOTER #mainNAV ul li.btn a {
                height:80px;
                background-size: 70%;
            }            
            #mainFOOTER #mainNAV ul li.btn h2 {
                height:22px;    
                padding-top:56px;
                font-size:1.1em;
            }
/*            #mainFOOTER #mainNAV ul li#btnGAMES a {
                background-position:  50% 0;
            }
            #mainFOOTER #mainNAV ul li#btnHITS a {
                background-position:  50% -120px;
            }
            #mainFOOTER #mainNAV ul li#btnTOURNOIS a {
                background-position:  50% -240px;
            }
            #mainFOOTER #mainNAV ul li#btnCOMPTE a {
                background-position:  50% -360px;
            }             
 */           .nav-tabs > li > a {
                line-height:20px;
            } 
			#landingMain{
				width:100%;
				height: 90%;
				background-color:black;
				text-align:center;
				font-family:robotoregular;
			}
			#landingMain_2{
				width:100%;
				height: 90%;
				background-color:#683653;
				text-align:center;
				font-family:robotoregular;
			}
			#landingMain_3{
				width:100%;
				height: 90%;
				background-color:white;
				text-align:center;
				font-family:robotoregular;
			}#landingMain_3t{
				width:100%;
				height: 90%;
				background-color:transparent;
				text-align:center;
				font-family:robotoregular;
			}
			#landingMain_4{
				width:100%;
				height: 90%;
				background-color:black;
				text-align:center;
				font-family:robotoregular;
			}
			#landingMain_5{
				width:100%;
				height: 90%;
				background-image:url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/Background_Pleasure.jpg');
				background-repeat: no-repeat;
				/* background-size: 100% auto; */
				/* background-position: center top; */
				background-attachment: fixed;				
				text-align:center;
				font-family:robotoregular;
			}
			.landingMainContent{
				width:93%;
				margin-left: auto;
				margin-right: auto;
				display:block;
			}
			.landingMainContent_2{
				width:100%;
				margin-left: auto;
				margin-right: auto;
				display:block;
			}
			.landingMainContent_3{
				width:100%;
				margin-left: auto;
				margin-right: auto;
				display:block;
				background-image: url(https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/Background_Pleasure.jpg);
				background-repeat: no-repeat;
				/* background-size: 100% auto; */
				/* background-position: center top; */
				background-attachment: fixed;
			}
			.landingLeftArrow{
				width: 12%;
				float: left;
				visibility: hidden;

			}
			.landingSmartphone{
				width: 12%;
				float: left;
				position: absolute;
			}
			.landingRightArrow{
				width: 12%;
				float: right;
				visibility: hidden;
				}

			.landingPrice{
				font-size:2.1em;
				width:100%;
				text-align:center;
				color:white;
				background-color:grey;
				padding-top:5px;
				padding-bottom:5px;
			}
			.landingPrice_4{
				font-size:2.3em;
				width:100%;
				text-align:center;
				color:black;
				background-color:lightgrey;
				padding-top:6px;
				padding-bottom:6px;
			}.landingPrice_2SMS{
				font-size:1.8em;
				width:100%;
				text-align:center;
				color:grey;
				background-color:lightgrey;
			}
			.landingPrice_3{
				font-size:2.3em;
				width:100%;
				text-align:center;
				color:grey;
				background-color:lightgrey;
				padding-top:4px;
				padding-bottom:4px;
			}			
			.landingPrice_2_BE{
				font-size:2.2em;
				width:100%;
				text-align:center;
				color:grey;
				background-color:lightgrey;
				padding-top:6px;
				padding-bottom:6px;
			}			
			.landingTitle{
				font-size: 3.4em;
				letter-spacing: 1.6px;
				color: white;
				font-weight: bold;
				width: 100%;
			}
			.landingTitle_2{
				font-size: 2.2em;
				letter-spacing: 1.6px;
				color: white;
				font-weight: bold;
				width: 100%;
			}
			.landingTitleDiv{
				padding-top: 18px;
				padding-bottom: 14px;
			}
			.landingTitleDivLeft_2{
				
			}
			.landingTitleRight_2{
				padding: 5px;
				width: 45%;
				float: right;
				height: 100%;
				font-size: 1.2em;
				font-weight: bold;
				height: 47px;
				line-height: 55px;
			}
			.landingHeaderBgames_2{
				width: 45%;
				float: left;
				padding: 5px;	
			}

			.landingTitle2{
				font-size: 2.4em;			
				color:white;
				width:100%;
				padding-top:3px;
				padding-bottom:3px;
			}
			.landingSubTitle{
				font-size:1.8em;
				color:white;
				width:100%;
				padding-top:3px;
				font-weight:bold;
				padding-bottom:3px;
			}
			.landingTitle2ADW{
				font-size:3.5em;
				color:#00ff34;
				width:100%;
				padding-top:3px;
				padding-bottom:3px;
			}
			.landingTitle2ADW_2{
				font-size:1.6em;
				color:white;
				width:100%;
				padding-top:3px;
				padding-bottom:3px;
			}
			.landingSubTitleADW{
				font-size:2em;
				color:white;
				width:100%;
				padding-top:3px;
				font-weight:bold;
				padding-bottom:3px;
				letter-spacing:1.9px;
			}
			.landingSubTitleADW_2{
				font-size:2.4em;
				color:black;
				width:100%;
				padding-top:3px;
				font-weight:bold;
				padding-bottom:3px;
				letter-spacing:1.9px;
			}.landingSubTitleADW_10{
				font-size:2.4em;
				color:#404746;
				width:100%;
				padding-top:3px;
				font-weight:bold;
				padding-bottom:3px;
				letter-spacing:1.9px;
			}
			.landingSubTitleADW_3{
				font-size:1.8em;
				color:black;
				width:100%;
				padding-top:3px;
				font-weight:bold;
				padding-bottom:3px;
				letter-spacing:1.9px;
			}
			.landingSubTitleADW_4{
				font-size:13px;
				color:black;
				width:100%;
				padding-top:3px;
				font-weight:bold;
				padding-bottom:3px;
				letter-spacing:1px;
			}
			.landingLittlePicture{
				width:32%;
				padding:1px;
			}
			.landingLittlePicture_2{
				width:23%;
				padding:1px;
			}
			.landingBottom{
				width:90%;
				margin-left: auto; 
				margin-right: auto;
				padding-top: 10%;
				padding-bottom: 10%;	
			}
			.landingBottom_2{
				width:95%;
				margin-left: auto; 
				margin-right: auto;
				padding-top: 6%;
				padding-bottom: 6%;	
			}
			.landingMainPicture{
				width:100%;	
			}
			.landingMainPictureDiv{
				padding-top:3px;
				padding-bottom:3px;	
				margin-bottom: 18px;
				position: relative;
					
			}
			.imgLPheader{
				margin-left:auto;
				margin-right:auto;
				width:60%;
			}				
			.landingMainPictureDiv_2{
				padding-top:3px;
				padding-bottom:3px;	
				margin-bottom: 22px;
				position: relative;
					
			}
			.landingFormDiv{
				width:100%;
			}
			.landingForm{
				width:97%;
				margin:0;
				padding-bottom:0;
				margin-bottom:18px;
				margin-top:18px;
				position:relative;
			}
			.margintop5{
				margin-top:5px;
			}
			.landing3gOKbutton{
				border-radius:7px; 
				width: 100%;
				margin:0;
				background: yellow;
				color:black;
				font-weight:bold;
				display: block;
				margin-top: 5px;
				font-size: 2.4em;
				height:54px;
				line-height:54px;
			}
			.labelTOPsimpleLP{
				color: black;
				font-size: 0.8em;
				margin-top: 3px;
				    background-color: white;
			}
			.landingPhoneField{
				margin-bottom: 10px;
				font-size: 1em; 
				border-radius:7px;
				width: 70%; 
				background: white; 
				padding:2%;
				margin-bottom:5px;
			}
			.landingPhoneField_2{
				margin-bottom: 10px;
				font-size: 1em; 
				border-radius:7px;
				width: 66%; 
				background: lightgray; 
				padding:1%;
				margin-bottom:5px;
			}.formCustomInput1{
				padding:1%;
				margin-bottom:0px;
				border-radius:0px;
				width:88%;
				background-color:white;
				font-size:30px;
				height: 28px;
				margin-bottom: 75px;
			}
			.landingPhoneField_3{
				font-size: 19px; 
				border-radius:2px;
				width: 80%; 
				background: lightgray; 
				padding-left:0px;
				padding-right:0px;
				padding-top:6px;
				padding-bottom:6px;
				letter-spacing:0.1px;
				margin-bottom:0px;
				text-align:center;
			}	
			.landingMailField{
				margin-bottom: 10px;
				font-size: 1em; 
				border-radius:7px;
				width: 100%; 
				background: lightgray; 
				padding:2%;
				margin-bottom:5px;
			}		
			.noradiusbutton{
				border-radius: 0;
				width:80%;
			}
			.landing3gOKbutton_2{
				height: 47px;
				border-radius:7px;
				width: 97%;
				margin: 0;
				background:#00ff36;
				color:black;
				font-weight:bold;
				padding: 0px;
				margin-top: 5px;
			}
			.landing3gOKbutton_4{
				height: 47px;
				border-radius:7px;
				width: 80%;
				margin: 0;
				background:#683653;
				color:black;
				font-weight:bold;
				padding: 0px;
				margin-top: 5px;
			}

			.landing3gOKbutton_3{
				height:100%;
				border-radius:0px;
				width:80%;
				margin: 0;
				background:transparent;
				color:black;
				font-weight:bold;
				padding: 0px;
				margin-top: 5px;
			}
			.landing3gOKbutton_5{
				height:100%;
				border-radius:0px;
				width:70%;
				margin: 0;
				background:transparent;
				color:black;
				font-weight:bold;
				padding: 0px;
			}			
			.okbuttonXXX{
				width:71%;
			}
			.picZALP{
				width:60%;

			}			
				.menuTerms{
					text-align: center;
					padding: 5px;
					margin-bottom: 10px;
				}
				.landingtopLink{
					text-align:center;
					margin:0.5rem auto;
				}

				.landingScrollContent{
					position: relative;
					color: #808080;
					font-size: 12px;
					text-align: justify;
					background:#683653;
				}
				.landingScrollContent_t{
					position: relative;
					color: #808080;
					font-size: 12px;
					text-align: justify;
					background:transparent;
				}				
				.landingScrollContentPortail{
					position: relative;
					color: white;
					font-size: 12px;
					text-align: justify;
					background:#391a39;
				}
				.landingScrollContentPortail2{
					position: relative;
					color: white;
					font-size: 14px;
					text-align: justify;
					background:#391a39;
				}
				.landingScrollContent_2{
					position: relative;
					color: #808080;
					font-size: 12px;
					text-align: justify;
					background:white;
				}
				.landingScrollContent_3{
					position: relative;
					color: #808080;
					font-size: 12px;
					text-align: justify;
					background:black;
				}
				.landingScrollContent_4{
					position: relative;
					color: #808080;
					font-size: 12px;
					text-align: justify;
					background-image:url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/Background_Pleasure.jpg');
					background-repeat: no-repeat;
					/* background-size: 100% auto; */
					/* background-position: center top; */
					background-attachment: fixed;
				}
				.landingScrollContent a {
					color: #808080;
					text-decoration: underline;	
				}					
        }        
        
        
        
        @media screen and (max-width:568px) {
            #detailGAME #infosGAME #headerGAME h2 {max-width:214px;}            
        }
        
        
        /* SMARTPHONE : PORTRAIT */
        @media screen and (max-width:480px) {
            #indexGAMES ul.listGames li.game .editoGAME h3 {max-width:184px;}   
            .detailSECTION #infosGAME .headerSECTION h2 {max-width:170px;}   
            .boxHEADER {
                background-size: contain;
            }
			.iframeSize{
				height:300px;
				overflow:hidden;
				width:100%;

			}
			.ci_footer{
				left:0%;
				right:0%;
				bottom:-40%;
				padding-top:17%;
			}
            .box h4 {
                width:100%;
                font-size:1.1em;text-align:center;
            }
            .box div.step {
                width:80%;
                margin:5px auto;
                padding:0;
            }
			.divlaunchvideo{
				background:black;
				width:95%;
				margin-left: auto;
				margin-right: auto;
				padding-bottom:10px;
				
			}
			.picLol{
				width:60%;
			}
			.spacervid{
				height:40px;
			}	
            .box p {
                width:80%;
                margin:0 auto;
                font-size:0.9em;line-height:1.0em;text-align:center;
				font-family:robotoregular;

            }            
            #indexGAMES ul.listGames li.game {



                height:64px;
                margin:0 0 8px 0;
                padding:4px;
				padding-left:18px;
				padding-right:18px;
            }
            #indexGAMES ul.listGames li.video {
                height:160px;
				text-align:center;
				border-bottom: transparent 1px solid;
				margin:0px;
				padding:0px;
			}
			.buttonLPAccount{
				padding: 0 7px;
				-webkit-border-radius: 4px;
				-moz-border-radius: 4px;
				border-radius: 4px;
				padding-bottom: 6px;
				padding-top: 6px;
				font-size:2em;line-height:25px;
			}
			
            #indexGAMES ul.listGames li.game a.gamePlay {
                right:4px;
				bottom:25px;
                max-width:50px;height:20px;
                padding: 0 7px;
                -webkit-border-radius:4px; 
                -moz-border-radius:4px; 
                border-radius:4px; 
                font-size:1.1em;line-height:20px;
				font-family:robotoregular;
				padding-bottom:3px;
				padding-top:3px;
                z-index:101;
				margin-right:2%;
            }
			.ptButton{
			    -webkit-border-radius:4px; 
                -moz-border-radius:4px; 
                border-radius:4px; 
				color:#ff7c67;
				padding-left: 4px;
			}
            #indexGAMES ul.listGames li.game .labs {
                top:4px;
                padding:0px 4px;
                font-size:0.9em;
            }
            #indexGAMES ul.listGames li.game .btnPICTOS {
                width:25%;max-width:60px;
            }        
            #indexGAMES ul.listGames li.game .btnPICTOS img {
                width:100%;max-width:60px;
            }
            #indexGAMES ul.listGames li.game .editoGAME {
                position:absolute;left:72px;
                max-width:220px;
				margin-left:4%;
            }   
            #indexGAMES ul.listGames li.game .editoGAME h3 {
			
                font-size:1.2em;
				letter-spacing:0.5px;
				font-family:robotoregular;
				text-transform:capitalize;
            }      
            #indexGAMES ul.listGames li.game .editoGAME p {
			   font-family:robotolight;
			   font-weight:bolder;
				font-size: 1.2em;

            }    
			.game_title{
				font-family:robotoregular;
				text-transform:capitalize;
				font-size:1.2em;
				letter-spacing:0.5px;
			}
			
			.game_subtitle{
			   font-family:robotolight;
			   font-weight:bolder;
				color:#9c9c9c;
				margin-top:3px;
			}
			
            #indexGAMES ul.listGames li.game .editoGAME p a {
                background:#000000;
                padding:3px 8px;
                -webkit-border-radius:4px; 
                -moz-border-radius:4px; 
                border-radius:4px;              
                display:inline-block;
            }            
            #moreGAMES ul.listGames li.game {
                -webkit-border-radius:20px; 
                -moz-border-radius:20px; 
                border-radius:20px;                
            }
            .detailSECTION {} 
            .detailSECTION #headerGAME {
                width:89%;height:64px;    
				padding-bottom:5px;
				padding-top:8px;
            }   
            .detailSECTION .headerSECTION {
               position:relative;top:0;left:0;
               width:100%;height:64px;             
               margin:0 auto 4px auto;
               overflow:hidden;
            }      
            .detailSECTION .headerSECTION .picto {
                width:25%;max-width:64px;
				padding-right:8px;
            } 
            .detailSECTION .headerSECTION .picto img {
				-webkit-border-radius:15px; 
                -moz-border-radius:15px; 
                border-radius:15px;             
                width:100%;
				max-width:100px;
				display:block;
				
            }       
			.detail_spacer {
			  display: block;
			  height: 1px;
			  border: 0;
			  border-top: 1px solid #c0c0c0;
			  margin: 1em 0;
			  padding: 0;

			}
			.scrollmenu_spacer{
				display: block;
				height: 1px;
				border: 0;
				border-bottom: #444545 1px solid;
			    padding: 0;
			}
			.scrollmenu_spacer_MCI{
				display: block;
				height: 1px;
				border: 0;
				border-bottom: #595F66 1px solid;
			    padding: 0;
			}	
            .detailSECTION .headerSECTION h2 {
                position:absolute;top:0px;left:76px;
                max-width:224px;
                font-size:1.6em;
                text-transform:uppercase;
            }              
            .detailSECTION .headerSECTION .btnACTION {
				font-family:robotoregular;
                top:0px;right:0px;
                height: 30px;
                padding:0 0px;
                font-size:1.1em;font-weight:bold;line-height:30px;        
				top:22px;color:#ff7c67;    

            }
            .detailSECTION .headerSECTION #btnACTION {}           
            .detailSECTION #infosGAME #longDES, .detailSECTION #infosGAME #longDEShidden, .detailSECTION #infosGAME #longDESshowed {
                text-align:left;
                font-size:1.1em;
            }               
            /* PAGE COMPTE */
            .statut p {
                font-size:1.2em;line-height:1.2em;
            }        
            #detailCOMPTE .bloc, #shareCOMPTE .bloc {
                width:90%;
            }
			
			#socialbox{
				width:100%;
				max-width:500px;
			}
			.socialbox{
				width:80%;
				max-width:500px;

			}
			.promo_bouygues{
				width:90%;
			}
			
            #shareCOMPTE .bloc a.picto {
                width:45px;height:45px;
                margin:0 12px 0 0;
            }                
            #detailCOMPTE .bloc p, #shareCOMPTE .bloc p {
                font-size:1.3em;line-height:45px;
            }
            #detailCOMPTE .spacerMID, #shareCOMPTE .spacerMID {
                width:80%;height:14px;
            } 
            /* FOOTER */
            #mainFOOTER {
                height:60px;
            }
            #mainFOOTER #mainNAV ul {
                height:60px;
            }
            #mainFOOTER #mainNAV ul li#btnGAMES a {
                background-position:  50% 2%;
            }
/*            #mainFOOTER #mainNAV ul li#btnHITS a {
                background-position:  50% -117px;
            }
            #mainFOOTER #mainNAV ul li#btnTOURNOIS a {
                background-position:  50% -236px;
            }
            #mainFOOTER #mainNAV ul li#btnCOMPTE a {
                background-position:  50% -355px;
            }             
*/
            #mainFOOTER #mainNAV ul li.btn a {
                height:60px;
                background-size: 65%;
            }
            #mainFOOTER #mainNAV ul li.btn {
                height:60px;    
            }
            #mainFOOTER #mainNAV ul li.btn h2 {
                padding-top:42px;
            }
			#mainLOGO img.logo {
				width:90%;
			}
			#mainLOGO img.logo2 {
				width:40%;
			}			
			.scroll_img_logo{
				width:26px;text-align:left;
			}
			#homepage-images .flex-caption {
				font-family:robotoregular;
				position: absolute;
				bottom: 0;
				left: 0;
				margin-bottom: 28px;
				margin-left: 20px;
				padding-top: 5px;
				padding-bottom:5px;
				padding-left:12px;
				padding-right:12px;
				color: white;
				-webkit-border-radius:3px; 
				-moz-border-radius:3px; 	
				border-radius:3px;  
				background-color: #ff7c67;
				font-size:1.3em;
				letter-spacing:1px;
			}  
			#homepage-images .flex-caption-sub {
				font-family:robotolight;
				position: absolute;
				bottom: 0;
				left: 0;
				margin-bottom: 8px;
				font-size:1.3em;
				letter-spacing:0.5px;
				margin-left: 20px;
				color: #9c9c9c;
				font-weight:bolder;
			}
			.account_title_nc{
				padding-top: 15px;
				padding-bottom: 15px;		
			}
			.account_title_nc_1{
				font-size: 2.18em;
				font-weight: bold;
				letter-spacing: 0.5px;
				line-height: 1;
			}
			.account_title_nc_2{
				font-family: robotoregular;      
				font-size: 3em;    
				font-weight: bold;      
				letter-spacing: 0.5px;
				line-height: 1.1;
			}
			.account_title_nc_3{
				font-size: 1.65em;
				font-family: robotoregular;
				line-height: 1.3;

			}
			.account_title_nc_4{
				font-family: robotoregular;
				letter-spacing: .5px;
				font-size: 1.49em;
				padding-top: 10px;
				padding-bottom: 20px;
				font-weight: bold;
				color: #C0BBBB;
			}
			.account_title_nc_5{
				color: black;
				font-family: robotoregular;
				font-size: 1.8em;
				background: white;
				font-weight: bold;
				padding-top: 2px;
				padding-bottom: 2px;
				padding-left: 9px;
				padding-right: 9px;		
			}.account_title_nc_6{
				padding-top: 5px;
				padding-bottom: 15px;
				font-family: robotoregular;
				font-size: 1.15em;
				font-weight: bold;
				letter-spacing: .4px;		
			}
			
			
			.account_title_nc_promoused_1 {
				letter-spacing: .5px;
				font-family: robotoregular;
				font-size: 2.9em;
				font-weight: bold;
			}
			.account_title_nc_promoused_2 {
				letter-spacing: .5px;
				font-family: robotoregular;
				font-size: 3.4em;
				font-weight: bold;			
			}
			.account_title_nc_promoused_3 {
				letter-spacing: .5px;
				font-family: robotoregular;
				font-weight: bold;
				font-size: 2.2em;
				padding-bottom: 4px;

				color: #C0BBBB;
			}		
			.account_title_c{
				font-family: robotoregular;

				padding-top:10px;
			}
			.account_title_c_1{
				font-family: robotoregular;
				font-size: 3.1em;
				font-weight: bold;
			}
			.account_title_c_2{
				font-family: robotoregular;
				font-size: 4em;
				font-weight: bold;
			}
			.account_title_c_3{
				font-family: robotoregular;
				font-size: 1.3em;
				padding-top: 15px;
				padding-bottom: 15px;
				line-height: 1.3;
			}
			.account_title_c_4{
				font-family: robotoregular;
				font-size: 1.5em;
				letter-spacing:.4px;
			}
			.account_title_c_4pt{
				font-family: robotoregular;
				font-size: 1.6em;
				letter-spacing:.4px;
			}			
			.account_title_c_5{
				font-size:1em;
				font-family: robotoregular;
			}
			.account_title_c_6{
				font-family: robotoregular;
				font-size:1em;
				letter-spacing:1.6px;
			}
			.account_title_c_1_MCI {
				font-family: robotoregular;
				font-size: 4.1em;
				font-weight: bold;
				padding-bottom: 4px;
				letter-spacing: 2px;
			}
			.account_title_c_2_MCI {
				font-family: robotoregular;
				font-size: 2.65em;
				font-weight: bold;
			}
			.account_title_c_3_MCI {
				font-family: robotoregular;
				font-size: 1.3em;
				padding-top: 16px;
				padding-bottom: 15px;
				line-height: 1.3;
				letter-spacing: .5px;
			}
			.account_title_c_4_MCI {
				font-family: robotoregular;
				font-size: 1.3em;
				letter-spacing: 1.4px;
			}
			.account_title_c_5_MCI {
				font-size: 1em;
				letter-spacing: 1.8px;
				font-family: robotoregular;
			}
			.account_title_c_6_MCI {
				font-family: robotoregular;
				font-size: 1em;
				letter-spacing: .9px;
			}		
			/* Account specs for MCI */ 
		.account_title_nc_1_MCI {
			font-size: 2.4em;
			font-weight: bold;
			letter-spacing: 20px;
			line-height: 1;
			margin-left: 11px;
		}	
		.account_title_nc_2_MCI {
			font-family: robotoregular;
			font-size: 4.1em;
			font-weight: bold;
			letter-spacing: 28px;
			line-height: 1.1;
			margin-left: 23px;
			padding-bottom: 14px;
		}
		.account_title_nc_3_MCI {
			font-size: 1.54em;
			font-family: robotoregular;
			line-height: 1;
			letter-spacing: 0;
			padding-bottom: 6px;
		}
		.account_title_nc_4_MCI {
			font-family: robotoregular;
			letter-spacing: 2.2px;
			font-size: 1.8em;
			padding-bottom: 25px;
			color: white;
			line-height: .5;
		}
		.account_title_nc_5_MCI {
			color: black;
			font-family: robotoregular;
			font-size: 2em;
			background: white;
			font-weight: bold;
			letter-spacing: 7px;
			padding-left: 10px;
			padding-right: 10px;
		}		
		.account_title_nc_6_MCI{
			padding-top: 5px;
			padding-bottom: 15px;
			font-family: robotoregular;
			font-size: 1.15em;
			font-weight: bold;
			letter-spacing: .4px;		
		}	
			.weekmonth_1{
				font-size: 2em;
				font-family: robotoregular;

			}
			.weekmonth_2{
				font-size: 1.5em;
				font-family: robotoregular;

			}
			.paddingTerms{
				padding-top:15px;
				padding-left:5px;
				padding-right:5px;
			}		
			.panel ul li a{
				font-size: 1.25em;
				text-decoration:none;
				text-transform:uppercase;
				letter-spacing: 0.4px;
				vertical-align: super;
				padding-left: 5px;
				padding-top: 1em;
				padding-bottom: 1em;
				box-sizing: border-box;
				width: 77%;		
				display:inline-block;
			}	
			.scrollmenu_subtitle{
				font-family:robotolight;
				*font-weight:bolder;
				color:#606566;
				padding-bottom:30px;
				    font-size: 1.2em;
			}
		
			.menuTerms{
				text-align: center;
				padding: 5px;
				margin-bottom: 10px;
			}
			.landingtopLink{
				text-align:center;
				margin:0.5rem auto;
			}	
			#landingMain{
				width:100%;
				height: 90%;
				background-color:black;
				text-align:center;
				font-family:robotoregular;
			}
			#landingMain_2{
				width:100%;
				height: 90%;
				background-color:#683653;
				text-align:center;
				font-family:robotoregular;
			}
			#landingMain_3{
				width:100%;
				height: 90%;
				background-color:white;
				text-align:center;
				font-family:robotoregular;
			}
			#landingMain_3t{
				width:100%;
				height: 90%;
				background-color:transparent;
				text-align:center;
				font-family:robotoregular;
			}

			#landingMain_4{
				width:100%;
				height: 90%;
				background-color:black;
				text-align:center;
				font-family:robotoregular;
			}
			#landingMain_5{
				width:100%;
				height: 90%;
				background-image:url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/Background_Pleasure.jpg');	
				background-repeat: no-repeat;
				/* background-size: 100% auto; */
				/* background-position: center top; */
				background-attachment: fixed;
				text-align:center;
				font-family:robotoregular;
			}			
			.landingMainContent{
				width:93%;
				margin-left: auto;
				margin-right: auto;
				display:block;
			}
			.landingMainContent_2{
				width:100%;
				margin-left: auto;
				margin-right: auto;
				display:block;
			}
			.landingMainContent_3{
				width:100%;
				margin-left: auto;
				margin-right: auto;
				display:block;
				background-image: url(https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/Background_Pleasure.jpg);
				background-repeat: no-repeat;
				/* background-size: 100% auto; */
				/* background-position: center top; */
				background-attachment: fixed;
			}
			.landingLeftArrow{
				width: 12%;
				float: left;
				visibility: hidden;
			}
			.landingSmartphone{
				width: 12%;
				float: left;
				position: absolute;
			}
			.landingRightArrow{
				width: 12%;
				float: right;
				visibility: hidden;
				}
			.landingPrice{
				width:100%;
				text-align:center;
				color:white;
				background-color:grey;
				padding-top:3px;
				padding-bottom:3px;
				font-size: 1em;
			}
			.landingPrice_2{
				width:100%;
				text-align:center;
				color:grey;
				background-color:lightgrey;
				padding-top:6px;
				padding-bottom:6px;
				font-size: 1.25em;
			}
			.landingPrice_4{
				width:100%;
				text-align:center;
				color:black;
				background-color:lightgrey;
				padding-top:7px;
				padding-bottom:7px;
				font-size: 1.40em;
			}
			.landingPrice_2SMS{
				width:100%;
				text-align:center;
				color:grey;
				background-color:lightgrey;
				font-size: 1.05em;
			}
			.landingPrice_3{
				width:100%;
				text-align:center;
				color:grey;
				background-color:lightgrey;
				padding-top:2px;
				padding-bottom:2px;
				font-size: 1.2em;
			}			
			.landingPrice_2_BE{
				width:100%;
				text-align:center;
				color:grey;
				background-color:lightgrey;
				padding-top:4px;
				padding-bottom:4px;
				font-size: 1em;
			}
			.landingTitle{
				font-size: 2.1em;
				letter-spacing: 1.6px;
				color: white;
				font-weight: bold;
				width: 100%;
			}
			.landingTitle_2{
				font-size: 1.4em;
				letter-spacing: 1.6px;
				color: white;
				font-weight: bold;
				width: 100%;
			}
			.landingTitleDiv{
				padding-top: 5px;
				padding-bottom: 2px;
			}
			.landingTitleDivLeft_2{
				
			}
			.landingTitleRight_2{
				padding: 5px;
				width: 45%;
				float: right;
				height: 100%;
				font-size: 1.2em;
				font-weight: bold;
				height: 47px;
				line-height: 55px;
			}
			.landingHeaderBgames_2{
				width: 45%;
				float: left;
				padding: 5px;	
			}

			.landingTitle2{
				font-size:1.3em;
				color:white;
				width:100%;
				padding-top:3px;
				padding-bottom:3px;
			}
			.landingSubTitle{
				font-size:1em;
				color:white;
				width:100%;
				padding-top:3px;
				font-weight:bold;
				padding-bottom:3px;
			}
			.landingTitle2ADW{
				font-size:2.5em;
				color:#00ff34;
				width:100%;
				padding-top:3px;
				padding-bottom:3px;
			}
			.landingTitle2ADW_2{
				font-size:1.1em;
				color:white;
				width:100%;
				padding-top:3px;
				padding-bottom:3px;
			}
			.landingSubTitleADW{
				font-size: 1.2em;
				color: white;
				width: 100%;
				padding-top: 3px;
				font-weight: bold;
				padding-bottom: 3px;
				letter-spacing: 2px;
			}
			.landingSubTitleADW_2{
				font-size: 1.45em;
				color: black;
				width: 100%;
				padding-top: 3px;
				font-weight: bold;
				padding-bottom: 3px;
				letter-spacing: 1.7px;
			}.landingSubTitleADW_10{
				font-size: 1.45em;
				color: #404746;
				width: 100%;
				padding-top: 3px;
				font-weight: bold;
				padding-bottom: 3px;
				letter-spacing: 1.7px;
			}				
			.landingSubTitleADW_3{
				font-size: 1em;
				color: black;
				width: 100%;
				padding-top: 3px;
				font-weight: bold;
				padding-bottom: 3px;
				letter-spacing: 1.7px;
			}
			.landingSubTitleADW_4{
				font-size: 13px;
				color: black;
				width: 100%;
				padding-top: 3px;
				font-weight: bold;
				padding-bottom: 3px;
				letter-spacing: 1px;
			}			
			.landingLittlePicture{
				width:32%;
				padding:1px;
			}
			.landingLittlePicture_2{
				width:23%;
				padding:1px;
			}
			.landingBottom{
				width:90%;
				margin-left: auto; 
				margin-right: auto;
				padding-top: 10%;
				padding-bottom: 10%;	
			}
			.landingBottom_2{
				width: 93%;
				margin-left: auto;
				margin-right: auto;
				padding-top: 3%;
				padding-bottom: 6%;
			}
			.landingMainPicture{
				width:100%;	
			}
			.landingMainPictureDiv{
				padding-top:3px;
				padding-bottom:3px;	
				margin-bottom: 2px;
				position: relative;

			}
			.imgLPheader{
				margin-left:auto;
				margin-right:auto;
				width:60%;
			}				
			.landingMainPictureDiv_2{
				padding-top:3px;
				padding-bottom:3px;	
				margin-bottom: 6px;
				position: relative;

			}
			.landingFormDiv{
				width:100%;
			}
			.landingForm{
				width:97%;
				margin:0;
				padding-bottom:0;
				margin-bottom:5px;
				margin-top:3px;

				position:relative;
			}
			.margintop5{
				margin-top:5px;
			}
			.landing3gOKbutton{
				border-radius:7px; 
				width: 100%;
				margin:0;
				background: yellow;
				color:black;
				font-weight:bold;
				display: block;
				margin-top: 5px;
				font-size: 2.4em;
				height:54px;
				line-height:54px;
			}
			.labelTOPsimpleLP{
				color: black;
				font-size: 0.8em;
				margin-top: 3px;
				    background-color: white;
			}
			.landingPhoneField{
				margin-bottom: 10px;
				font-size: 1em; 
				border-radius:7px;
				width: 70%; 
				background: white; 
				padding:2%;
				margin-bottom:5px;
			}
			.landingPhoneField_2{
				margin-bottom: 10px;
				font-size: 1em; 
				border-radius:7px;
				width: 66%;
				background: lightgray; 
				padding:1%;
				margin-bottom:5px;
			}.formCustomInput1{
				padding:1%;
				margin-bottom:0px;
				border-radius:0px;
				width:88%;
				background-color:white;
				font-size:30px;
				height: 28px;
				margin-bottom: 75px;
				
			}
			.landingPhoneField_3{
				font-size: 19px; 
				border-radius:2px;
				width: 80%; 
				background: lightgray; 
				padding-left:0px;
				padding-right:0px;
				padding-top:6px;
				padding-bottom:6px;
				letter-spacing:0.1px;
				margin-bottom:0px;
				text-align:center;

			}	
			.landingMailField{
				margin-bottom: 10px;
				font-size: 1em; 
				border-radius:7px;
				width: 100%; 
				background: lightgray; 
				padding:2%;
				margin-bottom:5px;
			}			
			.noradiusbutton{
				border-radius: 0;
				width:80%;
			}
			.landing3gOKbutton_2{
				height: 47px;
				border-radius:7px;
				width: 97%;
				margin: 0;
				background:#00ff36;
				color:black;
				font-weight:bold;
				padding: 0px;
				margin-top: 1px;
			}
			.landing3gOKbutton_4{
				height: 47px;
				border-radius:7px;
				width: 80%;
				margin: 0;
				background:#683653;
				color:black;
				font-weight:bold;
				padding: 0px;
				margin-top: 1px;
			}

			.landing3gOKbutton_3{
				height:100%;
				border-radius:0px;
				width:80%;
				margin: 0;
				background:transparent;
				color:black;
				font-weight:bold;
				padding: 0px;
				margin-top: 1px;
			}
			.landing3gOKbutton_5{
				height:100%;
				border-radius:0px;
				width:70%;
				margin: 0;
				background:transparent;
				color:black;
				font-weight:bold;
				padding: 0px;
			}			
			.okbuttonXXX{
				width:71%;
			}
			.picZALP{
				width:60%;

			}
			.menuTerms{
				text-align: center;
				padding: 5px;
				margin-bottom: 10px;
			}
			.landingtopLink{
				text-align:center;
				margin:0.5rem auto;
			}

			.landingScrollContent{
				position: relative;
				padding-left:30px;
				padding-right:30px;
				color: #808080;
				font-size: 12px;
				text-align: justify;
				background:#683653;
			}
			.landingScrollContent_t{
				position: relative;
				padding-left:30px;
				padding-right:30px;
				color: #808080;
				font-size: 12px;
				text-align: justify;
				background:transparent;
			}			
			.landingScrollContentPortail{
				position: relative;
				padding-left:30px;
				padding-right:30px;
				color: white;
				font-size: 12px;
				text-align: justify;
				background:#391a39;
			}
			.landingScrollContentPortail2{
				position: relative;
				padding-left:30px;
				padding-right:30px;
				color: white;
				font-size: 14px;
				text-align: justify;
				background:#391a39;
			}
			.landingScrollContent_2{
				position: relative;
				padding-left:30px;
				padding-right:30px;
				color: #808080;
				font-size: 12px;
				text-align: justify;
				background:white;
			}
			.landingScrollContent_3{
				position: relative;
				padding-left:30px;
				padding-right:30px;
				color: #808080;
				font-size: 12px;
				text-align: justify;
				background:black;
			}
			.landingScrollContent_4{
				position: relative;
				padding-left:30px;
				padding-right:30px;
				color: #808080;
				font-size: 12px;
				text-align: justify;
				background-image:url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/Background_Pleasure.jpg');
				background-repeat: no-repeat;
				/* background-size: 100% auto; */
				/* background-position: center top; */
				background-attachment: fixed;					
			}
			.landingScrollContent a {
				color: #808080;
				text-decoration: underline;	
			}

        }
        
        @media screen and (max-width:430px) {
			#indexGAMES ul.listGames li.video{
				height:138px;
			}
			.iframeSize{
				height:250px;
				overflow:hidden;
				width:100%;

			}
		}
		
        @media screen and (max-width:390px) {
            #mainLOGO {
                padding: 7px 0;
            }            
            h1 {
                padding:4px 0;
                font-size:1.4em;
            }   
            #titlePAGE {
                margin: 0 0 6px 0;
            }            
            #navCAT {
                padding: 4px 0 6px 0;
            }
			#indexGAMES ul.listGames li.video{
				height:162px;
			}			
            .navSECTION {
                position:fixed;bottom:70px;left:0;
            }
            /* FOOTER */
/*            #mainFOOTER {
               height: 40px;
            }
            #mainFOOTER #mainNAV ul {
                height: 40px;
            }
            #mainFOOTER #mainNAV ul li.btn {
                height: 40px;
            }
  */          #mainFOOTER #mainNAV ul li.btn a {
                background-size: 80%;
            }
   /*         #mainFOOTER #mainNAV ul li#btnHITS a {
                background-position:  50% -78px;
            }
            #mainFOOTER #mainNAV ul li#btnTOURNOIS a {
                background-position:  50% -58px;
            }
            #mainFOOTER #mainNAV ul li#btnCOMPTE a {
                background-position:  50% -237px;
            }             
            #mainFOOTER #mainNAV ul li.btn h2 {
                font-size:0.9em;
                padding-top: 28px;
            }            
 */           #indexGAMES ul.listGames li.game .editoGAME {
                max-width:140px;
				margin-left:4%;
            }  
            #navSECTIONGAME .nav-tabs>li,.nav-pills>li {
                width:31%;
                margin:0 1%;
                text-align:center;
                font-size:0.8em;
            }                        
            .detailSECTION .headerSECTION h2 {
                position:absolute;top:8px;left:76px;
                max-width:144px;
                font-size:1.6em;
                text-transform:uppercase;
            }                
            /* PAGE HITS */
            #moreGAMES ul.listGames li.game {margin:0 0 12px 0;}
            /* PAGE TOURNOIS */
            #navSECTIONTOURNOIS .nav-tabs>li,.nav-pills>li{
                width:44%;
                margin:0 3%;
            }        
				
        }

        @media screen and (max-width:330px) {
            #mainFOOTER #mainNAV ul li.btn a {
                background-size: 100%;
            }
			.iframeSize{
				height:210px;
				overflow:hidden;	
				width:100%;
			}
			.header_left{
				padding-top:5px;
			}
			#indexGAMES ul.listGames li.video{
				height:137px;
			}
			.panel ul li a{
				font-size: 1.07em;
				text-decoration:none;
				text-transform:uppercase;
				letter-spacing: 0.4px;
				vertical-align: super;
				padding-left: 5px;
				padding-top: 0.1em;
				padding-bottom: 0.1em;
				box-sizing: border-box;
				width: 77%;		
				display:inline-block;
			}
			.minilogos{
			    width: 7%;
				margin-left: 8px;
			}
			#homepage-images .flex-caption {
				font-family: robotoregular;
				position: absolute;
				bottom: 0;
				left: 0;
				margin-bottom: 23px;
				margin-left: 17px;
				padding-top: 3px;
				padding-bottom: 3px;
				padding-left: 8px;
				padding-right: 8px;
				color: white;
				-webkit-border-radius: 3px;
				-moz-border-radius: 3px;
				border-radius: 2px;
				background-color: #ff7c67;
				font-size: 1.1em;
				letter-spacing: 0.3px;
			}
			#homepage-images .flex-caption-sub {
				font-family: robotolight;
				position: absolute;
				bottom: 0;
				left: 0;
				margin-bottom: 7px;
				font-size: 1.1em;
				letter-spacing: .3px;
				margin-left: 17px;
				color: #9c9c9c;
				font-weight: bolder;			
			}
				#landingMain{
					width:100%;
					height: 90%;
					background-color:black;
					text-align:center;
					font-family:robotoregular;
				}
				#landingMain_2{
					width:100%;
					height: 90%;
					background-color:#683653;
					text-align:center;
					font-family:robotoregular;
				}
				#landingMain_3{
					width:100%;
					height: 90%;
					background-color:white;
					text-align:center;
					font-family:robotoregular;
				}
				#landingMain_3t{
					width:100%;
					height: 90%;
					background-color:transparent;
					text-align:center;
					font-family:robotoregular;
				}				
			
				#landingMain_4{
					width:100%;
					height: 90%;
					background-color:black;
					text-align:center;
					font-family:robotoregular;
				}
				#landingMain_5{
					width:100%;
					height: 90%;
					background-image:url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/Background_Pleasure.jpg');
					text-align:center;
					font-family:robotoregular;
					background-repeat: no-repeat;
					/* background-size: 100% auto; */
					/* background-position: center top; */
					background-attachment: fixed;
					
				}							
				.landingMainContent{
					width:93%;
					margin-left: auto;
					margin-right: auto;
					display:block;
				}
				.landingMainContent_2{
					width:100%;
					margin-left: auto;
					margin-right: auto;
					display:block;
				}
				.landingMainContent_3{
					width:100%;
					margin-left: auto;
					margin-right: auto;
					display:block;
					background-image: url(https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/Background_Pleasure.jpg);
					background-repeat: no-repeat;
					/* background-size: 100% auto; */
					/* background-position: center top; */
					background-attachment: fixed;					
				}
				.landingLeftArrow{
					width: 12%;
					float: left;
					visibility: hidden;

				}
				.landingSmartphone{
					width: 12%;
					float: left;
					position: absolute;
				}
				.landingRightArrow{
					width: 12%;
					float: right;
					visibility: hidden;
					}
				.landingPrice{
					font-size: 1em;		
					width:100%;
					text-align:center;
					color:white;
					background-color:grey;
					padding-top:4px;
					padding-bottom:4px;
				}
				.landingPrice_2{
					font-size: 0.9em;		
					width:100%;
					text-align:center;
					color:gray;
					background-color:lightgray;
					padding-top:4px;
					padding-bottom:4px;
				}
				.landingPrice_4{
					font-size: 1.1em;		
					width:100%;
					text-align:center;
					color:black;
					background-color:lightgray;
					padding-top:5px;
					padding-bottom:5px;
				}				
				.landingPrice_2SMS{
					font-size: 0.8em;		
					width:100%;
					text-align:center;
					color:gray;
					background-color:lightgray;
				}				
				.landingPrice_3{
					font-size: 1.3em;		
					width:100%;
					text-align:center;
					color:gray;
					background-color:lightgray;
					padding-top:4px;
					padding-bottom:4px;
				}				
				.landingPrice_2_BE{
					font-size: 1em;		
					width:100%;
					text-align:center;
					color:gray;
					background-color:lightgray;
					padding-top:5px;
					padding-bottom:5px;
				}
				.landingTitle{
					font-size: 1.6em;
					letter-spacing: 1.6px;
					color: white;
					font-weight: bold;
					width: 100%;
				}
				.landingTitle_2{
					font-size: 1.1em;
					letter-spacing: 1.6px;
					color: white;
					font-weight: bold;
					width: 100%;
				}
				.landingTitleDiv{
					padding-top: 7px;
					padding-bottom: 3px;
				}
				.landingTitleDivLeft_2{
					
				}
				.landingTitleRight_2{
					padding: 5px;
					width: 45%;
					float: right;
					height: 100%;
					font-size: 1.2em;
					font-weight: bold;
					height: 47px;
					line-height: 55px;
				}
				.landingHeaderBgames_2{
					width: 45%;
					float: left;
					padding: 5px;	
				}

				.landingTitle2{
					font-size: 1em;
					color:white;
					width:100%;
					padding-top:3px;
					padding-bottom:3px;
				}
				.landingSubTitle{
					font-size:0.9em;
					color:white;
					width:100%;
					padding-top:3px;
					font-weight:bold;
					padding-bottom:3px;
				}
	
				.landingTitle2ADW{
					font-size:2em;
					color:#00ff34;
					width:100%;
					padding-top:3px;
					padding-bottom:3px;
				}
				.landingTitle2ADW_2{
					font-size:0.9em;
					color:white;
					width:100%;
					padding-top:3px;
					padding-bottom:3px;
				}
				.landingSubTitleADW{
					font-size:1em;
					color:white;
					width:100%;
					padding-top:3px;
					font-weight:bold;
					padding-bottom:3px;
					letter-spacing:1.5px;
				}
				.landingSubTitleADW_2{
					font-size:1.45em;
					color:black;
					width:100%;
					padding-top:3px;
					font-weight:bold;
					padding-bottom:3px;
					letter-spacing:1.7px;
				}
				.landingSubTitleADW_10{
					font-size:1.45em;
					color:#404746;
					width:100%;
					padding-top:3px;
					font-weight:bold;
					padding-bottom:3px;
					letter-spacing:1.7px;
				}
				.landingSubTitleADW_3{
					font-size:1em;
					color:black;
					width:100%;
					padding-top:3px;
					font-weight:bold;
					padding-bottom:3px;
					letter-spacing:1.7px;
				}
				.landingSubTitleADW_4{
					font-size:13px;
					color:black;
					width:100%;
					padding-top:3px;
					font-weight:bold;
					padding-bottom:3px;
					letter-spacing:1px;
				}				
				.landingLittlePicture{
					width:32%;
					padding:1px;
				}
				.landingLittlePicture_2{
					width:22%;
					padding:1px;
				}
				.landingBottom{
					width:90%;
					margin-left: auto; 
					margin-right: auto;
					padding-top: 10%;
					padding-bottom: 10%;	
				}
				.landingBottom_2{
					width:95%;
					margin-left: auto; 
					margin-right: auto;
					padding-top: 6%;
					padding-bottom: 6%;	
				}
				.landingMainPicture{
					width:100%;	
				}
				.landingMainPictureDiv{
					padding-top:3px;
					padding-bottom:3px;	
					margin-bottom: 2px;
					position: relative;
					
				}
				.imgLPheader{
					margin-left:auto;
					margin-right:auto;
					width:60%;
				}				
				.landingMainPictureDiv_2{
					padding-top:3px;
					padding-bottom:3px;	
					margin-bottom: 5px;
					position: relative;
					
				}
				.landingFormDiv{
					width:100%;
				}
				.landingForm{
					width:97%;
					margin:0;
					padding-bottom:0;
					margin-bottom:2px;
					margin-top:4px;
					position:relative;
				}
				.margintop5{
					margin-top:5px;
				}
				.landing3gOKbutton{
					border-radius:7px; 
					width: 100%;
					margin:0;
					background: yellow;
					color:black;
					font-weight:bold;
					display: block;
					margin-top: 5px;
					font-size: 2.4em;
					height:54px;
					line-height:54px;
				}
				.landingPhoneField{
					margin-bottom: 10px;
					font-size: 1em; 
					border-radius:7px;
					width: 70%; 
					background: white; 
					padding:2%;
					margin-bottom:5px;
				}
				.labelTOPsimpleLP{
					color: black;
					font-size: 0.8em;
					margin-top: 3px;
					    background-color: white;
				}
				.landingPhoneField_2{
					margin-bottom: 10px;
					font-size: 1em; 
					border-radius:7px;
					width: 66%; 
					background: lightgray; 
					padding:1%;
					margin-bottom:5px;
				}.formCustomInput1{
					padding:1%;
					margin-bottom:0px;
					border-radius:0px;
					width:88%;
					background-color:white;
					font-size:30px;
					height: 28px;	
					margin-bottom: 75px;
					
				}
				.landingPhoneField_3{
					font-size: 19px; 
					border-radius:2px;
					width: 80%; 
					background: lightgray; 
					padding-left:0px;
					padding-right:0px;
					padding-top:6px;
					padding-bottom:6px;
					letter-spacing:0.1px;
					margin-bottom:0px;
					text-align:center;
				}			
				.landingMailField{
					margin-bottom: 10px;
					font-size: 1em; 
					border-radius:7px;
					width: 100%; 
					background: lightgray; 
					padding:2%;
					margin-bottom:5px;
				}
				.noradiusbutton{
					border-radius: 0;
					width:80%;
				}
				.landing3gOKbutton_2{
					height: 47px;
					border-radius:7px;
					width: 97%;
					margin: 0;
					background:#00ff36;
					color:black;
					font-weight:bold;
					padding: 0px;
					margin-top: 5px;
				}
				.landing3gOKbutton_4{
					height: 47px;
					border-radius:7px;
					width: 80%;
					margin: 0;
					background:#683653;
					color:black;
					font-weight:bold;
					padding: 0px;
					margin-top: 5px;
				}

				.landing3gOKbutton_3{
					border-radius:0px;
					width:80%;
					margin: 0;
					background:transparent;
					color:black;
					font-weight:bold;
					padding: 0px;
					margin-top: 5px;
				}
				.landing3gOKbutton_5{
					border-radius:0px;
					width:70%;
					margin: 0;
					background:transparent;
					color:black;
					font-weight:bold;
					padding: 0px;
				}				
				.okbuttonXXX{
					width:70%;
				}	
				.picZALP{
					width:60%;
				}
				.menuTerms{
					text-align: center;
					padding: 5px;
					margin-bottom: 10px;
				}
				.landingtopLink{
					text-align:center;
					margin:0.5rem auto;
				}

				.landingScrollContent{
					position: relative;
					color: #808080;
					font-size: 12px;
					text-align: justify;
					background:#683653;
				}
				.landingScrollContent_t {
					position: relative;
					color: #808080;
					font-size: 12px;
					text-align: justify;
					background:transparent;
				}				
				.landingScrollContentPortail{
					position: relative;
					color: white;
					font-size: 12px;
					text-align: justify;
					background:#391a39;
				}
				.landingScrollContentPortail2{
					position: relative;
					color: white;
					font-size: 14px;
					text-align: justify;
					background:#391a39;
				}				
				.landingScrollContent_2{
					position: relative;
					color: #808080;
					font-size: 12px;
					text-align: justify;
					background:white;
				}
				.landingScrollContent_3{
					position: relative;
					color: #808080;
					font-size: 12px;
					text-align: justify;
					background:black;
				}
				.landingScrollContent_4{
					position: relative;
					color: #808080;
					font-size: 12px;
					text-align: justify;
					background-image:url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/header/Background_Pleasure.jpg');
					background-repeat: no-repeat;
					/* background-size: 100% auto; */
					/* background-position: center top; */
					background-attachment: fixed;				
				}				
				.landingScrollContent a {
					color: #808080;
					text-decoration: underline;	
				}					
        }
        
      
        @media screen and (max-width:320px) {
			#indexGAMES ul.listGames li.video{
				height:137px;
			}
			.ci_footer{
				left:0%;
				right:0%;
				bottom:-60%;padding-top:17%;
			}
			.ci_10_title_span {
				font-family: robotoregular;
				font-size: 27px;
				color: white;
				font-weight: bold;
				line-height: 1;
			}
			.mystreaming7_headsplitt {
				display: inline-table;
				height: 55px;
			}
			.ci_subtitle_span{
				font-size: 12px;
			}
			.ci_head{
				padding-top: 15px !important;
			}
		}

        @media screen and (max-width:576px)
        { 
            #detailCOMPTE .payment .IMG {
            max-width: 235px;
            }
        }