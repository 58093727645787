/*
 * jQuery FlexSlider v2.5.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 and later license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 * 
 */
/* ====================================================================================================================
 * FONT-FACE
 * ====================================================================================================================*/
@font-face {
  font-family: 'flexslider-icon';
  src: url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/fonts/flexslider-icon.eot');
  src: url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/fonts/flexslider-icon.eot?#iefix') format('embedded-opentype'), url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/fonts/flexslider-icon.woff') format('woff'), url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/fonts/flexslider-icon.ttf') format('truetype'), url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/fonts/flexslider-icon.svg#flexslider-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'flexslider-icon';
  src: url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/fonts/flexslider-icon.eot');
  src: url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/fonts/flexslider-icon.eot?#iefix') format('embedded-opentype'), url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/fonts/flexslider-icon.woff') format('woff'), url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/fonts/flexslider-icon.ttf') format('truetype'), url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/fonts/flexslider-icon.svg#flexslider-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
    font-family: 'robotoregular';
    src: url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/fonts/Roboto-Regular-webfont.eot');
    src: url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/fonts/Roboto-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/fonts/Roboto-Regular-webfont.woff') format('woff'),
         url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/fonts/Roboto-Regular-webfont.ttf') format('truetype'),
         url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/fonts/Roboto-Regular-webfont.svg#robotoregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face{
	font-family: 'sfdisplay';
    src: url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/fonts/SfDisplay.otf');
    src: url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/fonts/SfDisplay.otf') format('otf');
    font-weight: normal;
    font-style: normal;
}
@font-face{
	font-family: 'sfdisplaybold';
    src: url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/fonts/SfDisplayBold.otf');
    src: url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/fonts/SfDisplayBold.otf') format('otf');
    font-weight: 700;
}
@font-face {
    font-family: 'robotolight';
    src: url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/fonts/Roboto-Light-webfont.eot');
    src: url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/fonts/Roboto-Light-webfont.eot?#iefix') format('embedded-opentype'),
         url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/fonts/Roboto-Light-webfont.woff') format('woff'),
         url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/fonts/Roboto-Light-webfont.ttf') format('truetype'),
         url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/fonts/Roboto-Light-webfont.svg#robotolight') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'robotomedium';
    src: url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/fonts/Roboto-Regular-webfont.eot');
    src: url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/fonts/Roboto-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/fonts/Roboto-Regular-webfont.woff') format('woff'),
         url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/fonts/Roboto-Regular-webfont.ttf') format('truetype'),
         url('https://d2bfhuxozzsc0y.cloudfront.net/InfinitWebPortal/medias/fonts/Roboto-Regular-webfont.svg#robotomedium') format('svg');
    font-weight: normal;
    font-style: normal;

}

/* ====================================================================================================================
 * RESETS
 * ====================================================================================================================*/
.flex-container a:hover,
.flex-slider a:hover,
.flex-container a:focus,
.flex-slider a:focus {
  outline: none;
}
.slides,
.slides > li,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}
.flex-pauseplay span {
  text-transform: capitalize;
}
/* ====================================================================================================================
 * BASE STYLES
 * ====================================================================================================================*/
.flexslider {
  margin: 0;
  padding: 0;
}
.flexslider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden;
}
.flexslider .slides img {
  width: 100%;
  display: block;
}
.flexslider .slides:after {
  content: "\0020";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
html[xmlns] .flexslider .slides {
  display: block;
}
* html .flexslider .slides {
  height: 1%;
}
.no-js .flexslider .slides > li:first-child {
  display: block;
}
/* ====================================================================================================================
 * DEFAULT THEME
 * ====================================================================================================================*/
 .flexslider_detail{
	padding-bottom:15px;
	background-color:#391a39;
 }
.flexslider {
  background: #391a39;
  position: relative;
  zoom: 1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
  -o-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
  box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
  margin-bottom:8px;
}
.flexslider .slides {
  zoom: 1;
}
.flexslider .slides img {
  height: auto;
}
.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.loading .flex-viewport {
  max-height: 300px;
}
.carousel li {
  margin-right: 5px;
}
.flex-direction-nav {
  *height: 0;
}


.flex-direction-nav a.flex-next:before {
  content: '\f002';
}
.flex-direction-nav .flex-prev {
}
.flex-direction-nav .flex-next {
  text-align: right;
	 right:0px;

}
.flexslider:hover .flex-direction-nav .flex-prev {
  opacity: 0.7;
}
.flexslider:hover .flex-direction-nav .flex-prev:hover {
  opacity: 1;
}
.flexslider:hover .flex-direction-nav .flex-next {
  opacity: 0.7;
}
.flexslider:hover .flex-direction-nav .flex-next:hover {
  opacity: 1;
}
.flex-direction-nav .flex-disabled {
  opacity: 0!important;
  filter: alpha(opacity=0);
  cursor: default;
}
.flex-pauseplay a {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 5px;
  left: 10px;
  opacity: 0.8;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;
  color: #000;
}
.flex-pauseplay a:before {
  font-family: "flexslider-icon";
  font-size: 20px;
  display: inline-block;
  content: '\f004';
}
.flex-pauseplay a:hover {
  opacity: 1;
}
.flex-pauseplay a.flex-play:before {
  content: '\f003';
}
.flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: -40px;
  text-align: center;
}
.flex-control-nav li {
  margin: 0 6px;
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.flex-control-paging li a {
  width: 11px;
  height: 11px;
  display: block;
  background: #666;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  text-indent: -9999px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -o-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}
.flex-control-paging li a:hover {
  background: #333;
  background: rgba(0, 0, 0, 0.7);
}
.flex-control-paging li a.flex-active {
  background: #000;
  background: rgba(0, 0, 0, 0.9);
  cursor: default;
}
.flex-control-thumbs {
  margin: 5px 0 0;
  position: static;
  overflow: hidden;
}
.flex-control-thumbs li {
  width: 25%;
  float: left;
  margin: 0;
}
.flex-control-thumbs img {
  width: 100%;
  height: auto;
  display: block;
  opacity: .7;
  cursor: pointer;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.flex-control-thumbs img:hover {
  opacity: 1;
}
.flex-control-thumbs .flex-active {
  opacity: 1;
  cursor: default;
}
/* ====================================================================================================================
 * RESPONSIVE
 * ====================================================================================================================*/
@media screen and (max-width: 1920px) {
  .flex-direction-nav .flex-prev {
    opacity: 1;
  }
  .flex-direction-nav .flex-next {
    opacity: 1;
	right:0px;
  }
  .flex-direction-nav a:before {
	  font-family: "flexslider-icon";
	  font-size: 25px;
	  display: inline-block;
	  content: '\f001';
	  background-color:rgba(0,0,0,0.5);
	  color: white;
	  padding:2px;
	  padding-left:8px;
	  padding-right:15px;
	  border-radius:1px;
	}
	.flex-direction-nav a {
	  text-decoration: none;
	  display: block;
	  width: 30px;
	  height: 30px;
	  margin: -20px 0 0;
	  position: absolute;
	  top: 50%;
	  z-index: 10;
	  overflow: hidden;
	  opacity: 0;
	  cursor: pointer;
	  color: transparent;
	  -webkit-transition: all 0.3s ease-in-out;
	  -moz-transition: all 0.3s ease-in-out;
	  -ms-transition: all 0.3s ease-in-out;
	  -o-transition: all 0.3s ease-in-out;
	  transition: all 0.3s ease-in-out;
	}
	.flexslider {
	  background: #391a39;
	  position: relative;
	  zoom: 1;
	  -webkit-border-radius: 4px;
	  -moz-border-radius: 4px;
	  border-radius: 4px;
	  -webkit-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
	  -moz-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
	  -o-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
	  box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
	  margin-bottom:8px;
	  width: 50%;
	  margin: auto;
	  display: block;	  
	}	
	.flex-viewport {
	  width: 100%;
	  display: block;
	  margin: auto;
	  margin-top: 11%;
	  max-height: 500px;
	  -webkit-transition: all 1s ease;
	  -moz-transition: all 1s ease;
	  -ms-transition: all 1s ease;
	  -o-transition: all 1s ease;
	  transition: all 1s ease;
	}
	.flexslider .slides img {
	  width: 100%;
	  display: block;
	}	
}
@media screen and (max-width: 860px) {
	.flexslider {
	  width: 100%;
	  background: #391a39;
	  position: relative;
	  zoom: 1;
	  -webkit-border-radius: 4px;
	  -moz-border-radius: 4px;
	  border-radius: 4px;
	  -webkit-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
	  -moz-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
	  -o-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
	  box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
	  margin-bottom:8px;
	}
	.flex-viewport {
	  width: 100%;
	  margin-top:4%;
	  max-height: 500px;
	  -webkit-transition: all 1s ease;
	  -moz-transition: all 1s ease;
	  -ms-transition: all 1s ease;
	  -o-transition: all 1s ease;
	  transition: all 1s ease;
	}
	.flexslider .slides img {
	  width: 100%;
	  display: block;
	}	
  .flex-direction-nav .flex-prev {
    opacity: 1;
  }
  .flex-direction-nav .flex-next {
    opacity: 1;
	right:0px;
  }
  .flex-direction-nav a:before {
	  font-family: "flexslider-icon";
	  font-size: 50px;
	  display: inline-block;
	  content: '\f001';
	  background-color:rgba(0,0,0,0.5);
	  color: white;
	  padding:5px;
	  padding-left:15px;
	  padding-right:30px;
	  border-radius:2px;
	}
	.flex-direction-nav a {
	  text-decoration: none;
	  display: block;
	  width: 60px;
	  height: 40px;
	  margin: -20px 0 0;
	  position: absolute;
	  top: 50%;
	  z-index: 10;
	  overflow: hidden;
	  opacity: 0;
	  cursor: pointer;
	  color: transparent;
	  -webkit-transition: all 0.3s ease-in-out;
	  -moz-transition: all 0.3s ease-in-out;
	  -ms-transition: all 0.3s ease-in-out;
	  -o-transition: all 0.3s ease-in-out;
	  transition: all 0.3s ease-in-out;
	}
	.flexslider .slides img {
	  width: 100%;
	  display: block;
	}
}
@media screen and (max-width:480px) {
	.flexslider {
	  background: #391a39;
	  position: relative;
	  zoom: 1;
	  -webkit-border-radius: 4px;
	  -moz-border-radius: 4px;
	  border-radius: 4px;
	  -webkit-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
	  -moz-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
	  -o-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
	  box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
	  margin-bottom:8px;
	  width: 100%;

	}	
	.flex-viewport {
	  width: 100%;
	  margin-top:0%;
	  max-height: 2000px;
	  -webkit-transition: all 1s ease;
	  -moz-transition: all 1s ease;
	  -ms-transition: all 1s ease;
	  -o-transition: all 1s ease;
	  transition: all 1s ease;
	}
	.flexslider .slides img {
	  width: 100%;
	  display: block;
	}	
	.flex-direction-nav a:before {
	  font-family: "flexslider-icon";
	  font-size: 20px;
	  display: inline-block;
	  content: '\f001';
	  background-color:rgba(0,0,0,0.5);
	  color: white;
	  padding:5px;
	  padding-left:15px;
	  padding-right:30px;
	  border-radius:2px;
	}
	.flex-direction-nav a {
	  text-decoration: none;
	  display: block;
	  width: 40px;
	  height: 40px;
	  margin: -20px 0 0;
	  position: absolute;
	  top: 50%;
	  z-index: 10;
	  overflow: hidden;
	  opacity: 0;
	  cursor: pointer;
	  color: transparent;
	  -webkit-transition: all 0.3s ease-in-out;
	  -moz-transition: all 0.3s ease-in-out;
	  -ms-transition: all 0.3s ease-in-out;
	  -o-transition: all 0.3s ease-in-out;
	  transition: all 0.3s ease-in-out;
	}	
}
@media screen and (max-width:330px) {
	.flex-direction-nav a:before {
		font-family: "flexslider-icon";
		font-size: 17px;
		display: inline-block;
		content: '\f001';
		background-color: rgba(0,0,0,0.5);
		color: white;
		padding: 4px;
		padding-left: 13px;
		padding-right: 16px;
		border-radius: 2px;
	}
	.flex-direction-nav a{
		width: 35px;

	}
}
